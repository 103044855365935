import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../styles/Hoomans.module.css';

const OneOfs: React.FC = () => {
  const images = [
    "/images//hoomans/3758.png",
    "/images//hoomans/3759.png",
    "/images//hoomans/3760.png",
    "/images//hoomans/3761.png",
    "/images//hoomans/3762.png",
    "/images//hoomans/3763.png",
    "/images//hoomans/3785.png",
    "/images//hoomans/3765.png",
    "/images//hoomans/3766.png",
    "/images//hoomans/3767.png",
    "/images//hoomans/3758.png",
    "/images//hoomans/3759.png",
    "/images//hoomans/3760.png",
    "/images//hoomans/3761.png",
    "/images//hoomans/3762.png",
    "/images//hoomans/3763.png",
    "/images//hoomans/3785.png",
    "/images//hoomans/3765.png",
    "/images//hoomans/3766.png",
    "/images//hoomans/3767.png",
    // Repeat or add as many images as needed to fill the space
  ];
   
    return (
      <>
        <Helmet>
          <title>Team</title>
          <meta name="description" content="Discover the SAGA ALIENS!" />
        </Helmet>
  
        <div className={styles.container}>
          <section className={styles.teamHeader}>
            <h2 className={styles.title}>Coming Soon</h2>
          </section>
          <div className={styles.scrollerWrapper}>
            <div className={styles.scroller}>
              {images.map((src, index) => (
                <img key={index} src={src} alt={`Scroller Image ${index}`} />
              ))}
            </div>
          </div>
          <section className={styles.hoomans}>
            <section className={styles.hoomansTitle}>
              <h2 className={styles.title2}>Coming Soon</h2>
            </section>
          </section>
        </div>
      </>
    );
  };
  
  export default OneOfs;
