// components/ResponsiveImageMap.tsx
import React, { useEffect } from 'react';
import styles from '../styles/Hybrids.module.css';

const ImageMap = () => {
  return (
    <svg className={styles.hybridMap} width= "100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="none">
        <image href="/mainpage.gif" width="1920" height="1080" />
        <a href="https://discord.com/invite/mbJKQyCCkQ" target="_blank">
            <circle cx="1784" cy="772" r="79" fill="transparent"/>
        </a>
        <a href="/mothership">
            <circle cx="148" cy="306" r="77" fill="transparent"  />
        </a>
        <a href="/alerts">
            <circle cx="142" cy="771" r="78" fill="transparent"  />
        </a>
        <a href="https://x.com/GalacticHeist">
            <circle cx="1778" cy="303" r="78" fill="transparent" />
        </a>
        <a href="/aliens">
            <rect x="463" y="290" width="260" height="180" fill="transparent" />
        </a>
        <a href="/morph">
            <rect x="463" y="576" width="260" height="180" fill="transparent"/>
        </a>
            <a href="/faq">
            <rect x="1200" y="576" width="260" height="180" fill="transparent" />
        </a>
        <a href="/hybrids">
            <rect x="1200" y="290" width="260" height="180" fill="transparent"/>
        </a>
        <a href="/hoomans">
            <rect x="824" y="290" width="270" height="180" fill="transparent"/>
        </a>
        <a href="/intergalactichub">
            <rect x="824" y="576" width="270" height="180" fill="transparent" />
        </a>
    </svg>
);
};

export default ImageMap;
