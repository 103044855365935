import React from 'react';
import styles from '../styles/Hybrids.module.css';

const HybridMap = () => {
  return (
    <svg className={styles.hybridMap} width= "100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="none">
      <image href="/base.gif" width="1920" height="1080" />
      <a href="https://discord.com/invite/mbJKQyCCkQ" target="_blank">
        <circle cx="1784" cy="772" r="79" fill="transparent" />
      </a>
      <a href="/mothership">
        <circle cx="148" cy="306" r="77" fill="transparent" />
      </a>
      <a href="/alerts">
        <circle cx="142" cy="771" r="78" fill="transparent" />
      </a>
      <a href="https://twitter.com/GalacticHeist">
        <circle cx="1778" cy="303" r="78" fill="transparent" />
      </a>
      <a href="/alien">
        <rect x="994" y="242" width="167" height="167" fill="transparent" />
      </a>
      <a href="/1o1s">
        <rect x="795" y="357" width="167" height="167" fill="transparent" />
      </a>
      <a href="https://magiceden.io/marketplace/saga_aliens">
        <rect x="1205" y="605" width="135" height="135" fill="transparent" />
      </a>
      <a href="https://anybodies.com/c/sagaaliens">
        <rect x="1367" y="605" width="135" height="135" fill="transparent" />
      </a>
    </svg>
  );
};

export default HybridMap;