import React from 'react';
import Collapsible from './Collapsible';
import { Filter } from './types';

interface FilterSidebarProps {
  filters: Filter;
  selectedFilters: Filter;
  onFilterChange: (trait_type: string, value: string, isChecked: boolean) => void;
  traitCounts: { [key: string]: number };
  valueCounts: { [key: string]: { [key: string]: number } };
}

const FilterSidebar2: React.FC<FilterSidebarProps> = ({ filters, selectedFilters, onFilterChange, traitCounts, valueCounts }) => {
  return (
    <div style={{ marginLeft:'20%', width: '17%', padding: '20px', color: 'white', overflowY: 'auto', height: 'calc(60vh - 80px)', position: 'fixed', top: '22%', zIndex:12 }}>
      {Object.keys(filters).map(trait_type => (
        <Collapsible key={trait_type} title={trait_type} count={traitCounts[trait_type]}>
          {filters[trait_type].map(value => (
            <label key={value} style={{ display: 'block', margin: '10px 20px', fontSize:'1.35vw', textAlign: 'left' }}>
              <input
                type="checkbox"
                checked={selectedFilters[trait_type]?.includes(value)}
                onChange={(e) => onFilterChange(trait_type, value, e.target.checked)}
              />
              {value} ({valueCounts[trait_type][value]})
            </label>
          ))}
        </Collapsible>
      ))}
    </div>
  );
};

export default FilterSidebar2;