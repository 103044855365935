import React from 'react';
import GovNav from './GovNav';
import './Governance.css';
import SolBalance from '../components/SolBalance';

const Governance: React.FC = () => {
  return (
    <div>
      <GovNav />
      <div className="govwrapper">
        <div className="governance-page">
          <h2>$MORPH Governance</h2>
          <SolBalance />
          <p>Welcome to the governance portal! Please use the navigation above to submit proposals, view live proposals, or see finalized proposals.</p>
          <div className="guidelines-section">
            <h2>Galactic Governance Rules and Information</h2>
            <p><em>The following rules and information are subject to be changed, removed, and/or added to by the Galactic Heist Syndicate team in order to meet the needs of the Galactic Heist Syndicate ecosystem as it grows.</em></p>
            
            <h3>Proposal Submission and Voting Eligibility Criteria</h3>
            <ul>
              <li><strong>1.</strong> To be eligible for voting and proposal submissions, you must hold at least 1 ALIEN NFT OR a balance of $MORPH that is equal to the current floor price of 1 ALIEN NFT. Currently 10b $MORPH.</li>
              <li><strong>2.</strong> The amount of $MORPH tokens required to be eligible will be adjusted on an ongoing basis to ensure that the requirement of $MORPH holdings being at least the floor price of 1 ALIEN NFT is satisfied. The $MORPH requirement will be displayed on this site.</li>
            </ul>
            
            <h3>Proposal Submission, Review, and Voting Process</h3>
            <ul>
              <li><strong>1.</strong> Proposals can be submitted on the "submit" page by those who meet the eligibility requirements.</li>
              <li><strong>2.</strong> All submitted proposals will automatically be posted on the live proposals page. Those that don't meet guidlines may be removed by the team. Notifications will be made if a removal happens.</li>
              <li><strong>3.</strong> Live proposals will have a two week voting period. Each eligible wallet may only vote once per proposal.</li>
              <li><strong>4.</strong> The system logs every wallet that votes. All proposal voting will be reviewed once the voting period ends to ensure fidelity.</li>
              <li><strong>5.</strong> Proposals will be automatically moved to the finalized page once their voting period has ended.</li>
            </ul>

            <h3>Proposal Guidelines</h3>
            <ol>
            <li>
              <strong>Compliance with Laws and Regulations:</strong>
              <ul>
                <li>Proposals must comply with all applicable laws and regulations. Proposals that seek to provide dividends or any form of financial return to token holders will be automatically rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Relevance to the Project:</strong>
              <ul>
                <li>Proposals must be relevant to the project’s mission and goals. Irrelevant proposals will not be considered.</li>
              </ul>
            </li>
            <li>
              <strong>Clarity and Specificity:</strong>
              <ul>
                <li>Proposals should be clear, specific, and actionable. They should provide a detailed description of the issue, the proposed solution, and the expected impact.</li>
              </ul>
            </li>
            <li>
              <strong>Respectful and Non-Discriminatory:</strong>
              <ul>
                <li>Proposals must be respectful and free from discriminatory, hateful, or offensive content. Proposals that contain such content will be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>No Self-Promotion or Advertising:</strong>
              <ul>
                <li>Proposals must not be used for self-promotion or advertising of external products or services. Such proposals will be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Budget and Resource Considerations:</strong>
              <ul>
                <li>Proposals should include a realistic assessment of the required budget and resources. Proposals that lack feasibility in terms of budget or resources may be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Single Issue Focus:</strong>
              <ul>
                <li>Each proposal should focus on a single issue or topic. Combining multiple issues into one proposal is discouraged and may lead to rejection.</li>
              </ul>
            </li>
            <li>
              <strong>Community Benefit:</strong>
              <ul>
                <li>Proposals should aim to benefit the entire community or project. Proposals that serve only a small group or individual interests will be scrutinized closely.</li>
              </ul>
            </li>
            <li>
              <strong>Transparency and Accountability:</strong>
              <ul>
                <li>Proposals should include mechanisms for transparency and accountability. This may include regular updates, reporting, or measurable milestones.</li>
              </ul>
            </li>
            <li>
              <strong>Respect for Previous Decisions:</strong>
              <ul>
                <li>Proposals that seek to overturn or significantly alter previous community decisions should provide strong justification and evidence of widespread community support.</li>
              </ul>
            </li>
          </ol>

            <h3>Treasury</h3>
            <ul>
              <li><strong>1.</strong> Treasury wallet is 29kUxiS4FjpBeXMrK2RGeXPrkBvjoexXW5MA9MkF7iaj.</li>
              <li><strong>2.</strong> Eligible holders can submit proposals on how to use the treasury. This CAN NOT include direct compensation back to ALL holders because that would be similar to dividends. Need to keep everything legal.</li>
              <li><strong>3.</strong> All staking fee rewards automatically go to this wallet.</li>
              <li><strong>4.</strong> Real time treasury balance is viewable on top of this page.</li>
            </ul>

            <h3>Voter Rewards</h3>
            <ul>
              <li><strong>1.</strong> Eligible voters who participate in any project votes will be entered in a raffle. This is separate from 1 of 1 art voting.</li>
              <li><strong>2.</strong> The raffle will begin after a vote concludes.</li>
              <li><strong>3.</strong> Three winners will take home $20 in $MORPH.</li>
              <li><strong>4.</strong> Winners will be posted in the #│project-voting channel in our discord and they must submit a ticket with their wallet address to claim the rewards.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Governance;