// pages/index.tsx
import React from 'react';
import ImageMap from '../components/ImageMap';
import styles from '../styles/Hybrids.module.css'
import ImageMap2 from '../components/ImageMap2';


const Mothership: React.FC = () => {
  return (
    <>    <div className={styles.homecontainer}>
      <ImageMap />
    </div>
      <div className={styles.mobilehomecontainer}>
        <img  className={styles.mapImage}src="/mhome3.gif" alt="" />
        <ImageMap2/>
      </div>
    </>

  );
}

export default Mothership;
