import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FilterSidebar2 from '../components/FilterSidebar2';
import ImageGallery3 from '../components/ImageGallery3';
import NFTModal2 from '../components/NFTModal2';
import FilterMapA from '../components/FilterMapA';
import styles from '../styles/Alien2.module.css';
import rawData from '../files/SAmasterMD.json';
import AlienMap from '../components/AlienMap';
import AlienMap2 from '../components/AlienMap2';
import FilterSidebar2M from '../components/FilterSidebar2M';
import ImageGallery3M from '../components/ImageGallery3M';
import NFTModal2M from '../components/NFTModal2M';

const nftsData: NFT[] = rawData as NFT[];

interface Attribute {
  trait_type: string;
  value: string;
}

interface NFT {
  name: string;
  image: string;
  attributes: Attribute[];
}

interface Filter {
  [trait_type: string]: string[];
}

const Alien = () => {
  const [nfts, setNfts] = useState<NFT[]>(nftsData);
  const [filters, setFilters] = useState<Filter>({});
  const [traitCounts, setTraitCounts] = useState<{ [key: string]: number }>({});
  const [valueCounts, setValueCounts] = useState<{ [key: string]: { [key: string]: number } }>({});
  const [selectedFilters, setSelectedFilters] = useState<Filter>({});
  const [selectedNFT, setSelectedNFT] = useState<NFT | null>(null);

  useEffect(() => {
    const localFilters: Filter = {};
    const localTraitCounts: { [key: string]: number } = {};
    const localValueCounts: { [key: string]: { [key: string]: number } } = {};

    nfts.forEach(nft => {
      nft.attributes.forEach(attr => {
        if (!localFilters[attr.trait_type]) {
          localFilters[attr.trait_type] = [];
          localTraitCounts[attr.trait_type] = 0;
          localValueCounts[attr.trait_type] = {};
        }
        if (!localFilters[attr.trait_type].includes(attr.value)) {
          localFilters[attr.trait_type].push(attr.value);
        }
        localTraitCounts[attr.trait_type]++;
        localValueCounts[attr.trait_type][attr.value] = (localValueCounts[attr.trait_type][attr.value] || 0) + 1;
      });
    });

    setFilters(localFilters);
    setTraitCounts(localTraitCounts);
    setValueCounts(localValueCounts);
  }, [nfts]);

  const handleFilterChange = (trait_type: string, value: string, isChecked: boolean) => {
    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (isChecked) {
        if (!updatedFilters[trait_type]) {
          updatedFilters[trait_type] = [];
        }
        updatedFilters[trait_type].push(value);
      } else {
        updatedFilters[trait_type] = updatedFilters[trait_type].filter(v => v !== value);
        if (updatedFilters[trait_type].length === 0) {
          delete updatedFilters[trait_type];
        }
      }
      return updatedFilters;
    });
  };

  const onNFTClick = (nft: NFT) => {
    console.log('NFT clicked:', nft);
    setSelectedNFT(nft);
  };

  const filteredNfts = nfts.filter(nft =>
    Object.keys(selectedFilters).length === 0 || Object.entries(selectedFilters).every(([key, values]) =>
      nft.attributes.some(attr => key === attr.trait_type && values.includes(attr.value))
    )
  );

  return (
    <>
      <div className={styles.alien3}>
        <FilterMapA />
        <img className={styles.testerr} src="/Filterpage.gif" alt="" />
        <nav className={styles.aliennav}>
          <Link to="/alien" className={styles.sheetLink2}>ALIENS</Link>
          <Link to="/hooman" className={styles.sheetLink}>HOOMANS</Link>
        </nav>
        <div style={{ display: 'flex' }}>
          <FilterSidebar2 filters={filters} selectedFilters={selectedFilters} onFilterChange={handleFilterChange} traitCounts={traitCounts} valueCounts={valueCounts} />
          <ImageGallery3 nfts={filteredNfts} onNFTClick={onNFTClick} />
        </div>
        {selectedNFT && <NFTModal2 nft={selectedNFT} onClose={() => setSelectedNFT(null)} />}
      </div>

      <div className={styles.mobilehomecontainer3}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />   
        <nav className={styles.aliennav}>
          <Link to="/alien" className={styles.sheetLink2}>ALIENS</Link>
          <Link to="/hooman" className={styles.sheetLink}>HOOMANS</Link>
        </nav>
        <div style={{ display: 'flex' }}>
          <FilterSidebar2M filters={filters} selectedFilters={selectedFilters} onFilterChange={handleFilterChange} traitCounts={traitCounts} valueCounts={valueCounts} />
          <ImageGallery3M nfts={filteredNfts} onNFTClick={onNFTClick} />
        </div>
        {selectedNFT && <NFTModal2M nft={selectedNFT} onClose={() => setSelectedNFT(null)} />}
      </div>
    </>
  );
};

export default Alien;