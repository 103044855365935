import React, { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { chatService } from '../services/api';
import Starfield2 from './Starfield2';
import './Tester.css';

interface ConversationLibraryProps {
  show: boolean;
  startNewChat: () => void;
  loadConversation: (id: string) => void;
}

const ConversationLibrary: React.FC<ConversationLibraryProps> = ({ show, startNewChat, loadConversation }) => {
  const [conversations, setConversations] = useState<{ id: string; name: string }[]>([]);
  const [renamingId, setRenamingId] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>('');
  const { publicKey } = useWallet();

  useEffect(() => {
    if (publicKey) {
      fetchConversations();
    }
  }, [publicKey]);

  const fetchConversations = async () => {
    if (!publicKey) return;
    try {
      const fetchedConversations = await chatService.getConversations(publicKey.toString());
      setConversations(fetchedConversations.map((conv: any) => ({
        id: conv.id || conv.conversation_id,
        name: conv.name || `Conversation ${conv.id || conv.conversation_id}`
      })));
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const renameConversation = async (id: string, newName: string) => {
    if (!newName.trim() || !publicKey) {
      cancelRenaming();
      return;
    }
    try {
      await chatService.renameConversation(id, newName, publicKey.toString());
      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.id === id ? { ...conv, name: newName } : conv
        )
      );
    } catch (error) {
      console.error('Error renaming conversation:', error);
      // Handle error (e.g., show an error message to the user)
    }
    cancelRenaming();
  };

  const deleteConversation = async (id: string) => {
    if (!id || !publicKey) return;
    try {
      await chatService.deleteConversation(id, publicKey.toString());
      setConversations(prevConversations => prevConversations.filter(conv => conv.id !== id));
    } catch (error) {
      console.error('Error deleting conversation:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const startRenaming = (id: string) => {
    setRenamingId(id);
    const conversation = conversations.find(conv => conv.id === id);
    setNewName(conversation ? conversation.name : '');
  };

  const submitRename = (id: string) => {
    renameConversation(id, newName);
  };

  const cancelRenaming = () => {
    setRenamingId(null);
    setNewName('');
  };

  const handleStartNewChat = () => {
    const newConversationId = Date.now().toString();
    const newConversationName = `New Conversation`;
    setConversations(prevConversations => [
      { id: newConversationId, name: newConversationName },
      ...prevConversations
    ]);
    startNewChat();
  };

  if (!publicKey) {
    return null;
  }

  return (
    <div id="conversation-library" className={`conversation-library ${show ? '' : 'hidden'}`}>
  <h2 className="conversation-title">Conversations</h2>
  <ul id="conversation-list" className="conversation-list">
    {conversations.map((conversation) => (
      <li key={conversation.id} className="conversation-item">
        {renamingId === conversation.id ? (
          <div className="renaming-container">
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="renaming-input"
              placeholder="New name"
            />
            <button onClick={() => submitRename(conversation.id)} className="confirm-btn">
              <i className="fas fa-check"></i>
            </button>
            <button onClick={cancelRenaming} className="cancel-btn">
              <i className="fas fa-times"></i>
            </button>
          </div>
        ) : (
          <>
            <span onClick={() => loadConversation(conversation.id)}>{conversation.name}</span>
            <div>
              <button onClick={() => startRenaming(conversation.id)} className="edit-btn">
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => deleteConversation(conversation.id)}
                className="delete-btn"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </>
        )}
      </li>
    ))}
  </ul>
  <button id="new-chat" className="new-chat-btn glow-effect" onClick={handleStartNewChat}>
    New Chat
  </button>
</div>
  );
};

export default ConversationLibrary;