import React from 'react';
import { Helmet } from 'react-helmet'; // Ensure you've installed react-helmet
import styles from '../styles/Team.module.css'; // Ensure the corresponding CSS file is styled appropriately
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { useEffect } from 'react';

const TeamInfo = () => {
  return (
    <>
      <Helmet>
        <title>Team</title>
        <meta name="description" content="Discover the SAGA ALIENS team!" />
      </Helmet>
      <div className={styles.container}>
        <section className={styles.teamHeader}>
          <img className={styles.teamimage} src='/images/morphtoken.png' />
          <h2 className={styles.title}>GALACTIC HEIST TEAM</h2>
          <img className={styles.teamimage} src='/images/morphtoken.png' />
        </section>
        <div className={styles.teamContainer}>
          <div className={styles.teamSection}>
            <div className={styles.teamList}>
              <div className={styles.teamList2}>
                <li className={styles.teamItem}>
                  <img className={styles.pfpimage} src="/images/trxntalien.png" alt="trxnt" />
                  <h1 className={styles.teamtext}>Trxnt (Artist/Founder)</h1>
                  <Link to="https://twitter.com/boredtrxnt" className={styles.teamlink}>Twitter</Link>
                </li>
                <li className={styles.teamItem2}> My passion for creativity and art began in my school years, where I discovered graphic design at the age of 14. I diligently participated in recruitment challenges to join Call of Duty sniping clans, honing my skills and even earning income through rebranding projects for various clients. This early engagement in design was nurtured by my habitual practice of sketching characters and graffiti. Now, at 23, my dedication to the creative arts remains unwavering. My aspiration is to create my own ongoing digital series, achieving success in the field of drawing and animation. I draw inspiration from acclaimed adult animated series like "Family Guy," "American Dad," and "South Park." My ongoing journey in drawing and animation is one of constant learning and skill enhancement.
                </li>
              </div>
            </div>
          </div>
          <div className={styles.teamSection2}>
            <div className={styles.teamList}>
              <div className={styles.teamList2}>
                <li className={styles.teamItem2}> Hello Hoomans! I'm DocLove! My professional background includes being a board-certified behavior analyst, as well as a developer for Ethereum and Solana. Additionally, I bring expertise as a creative consultant. I started crypto in 2017 with trading and have been actively engaged for the last seven years. My start in the NFT space began in 2018, with Ethermon being my first NFT purchase! In 2021, my involvement deepened as I started assisting various projects in their launch phases, contributing in creative capacities encompassing marketing, community management, and high-level business operations. My experience as a BCBA has been instrumental, allowing me to apply behavioral analysis techniques across many business aspects. This journey later evolved into self-taught programming, focusing initially on Ethereum and then Solana. The creative process in this domain is something I really love!
                </li>
                <li className={styles.teamItem}>
                  <img className={styles.pfpimage} src="/docsalien.png" alt="DocLove" />
                  <h1 className={styles.teamtext}>DocLove (Dev/Operations)</h1>
                  <Link to="https://twitter.com/_doclove" className={styles.teamlink}>Twitter</Link>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mtcontainer}>
        <section className={styles.teamHeader}>
          <h2 className={styles.title}>SAGA ALIENS TEAM</h2>
        </section>
        <div className={styles.teamContainer}>
          <div className={styles.teamSection}>
            <div className={styles.teamList}>
              <div className={styles.teamList2}>
                <li className={styles.teamItem}>
                  <img className={styles.pfpimage} src="/images/trxntalien.png" alt="trxnt" />
                  <h1 className={styles.teamtext}>Trxnt (Artist/Founder)</h1>
                  <Link to="https://twitter.com/boredtrxnt" className={styles.teamlink}>Twitter</Link>
                </li>
                <li className={styles.teamItem2}> My passion for creativity and art began in my school years, where I discovered graphic design at the age of 14. I diligently participated in recruitment challenges to join Call of Duty sniping clans, honing my skills and even earning income through rebranding projects for various clients. This early engagement in design was nurtured by my habitual practice of sketching characters and graffiti. Now, at 23, my dedication to the creative arts remains unwavering. My aspiration is to create my own ongoing digital series, achieving success in the field of drawing and animation. I draw inspiration from acclaimed adult animated series like "Family Guy," "American Dad," and "South Park." My ongoing journey in drawing and animation is one of constant learning and skill enhancement.
                </li>
              </div>
            </div>
          </div>
          <div className={styles.teamSection2}>
            <div className={styles.teamList}>
              <div className={styles.teamList2}>
              <li className={styles.teamItem}>
                  <img className={styles.pfpimage} src="/docsalien.png" alt="DocLove" />
                  <h1 className={styles.teamtext}>DocLove (Dev/Operations)</h1>
                  <Link to="https://twitter.com/_doclove" className={styles.teamlink}>Twitter</Link>
                </li>
                <li className={styles.teamItem2}> Hello Hoomans! I'm DocLove! My professional background includes being a board-certified behavior analyst, as well as a developer for Ethereum and Solana. Additionally, I bring expertise as a creative consultant. I started crypto in 2017 with trading and have been actively engaged for the last seven years. My start in the NFT space began in 2018, with Ethermon being my first NFT purchase! In 2021, my involvement deepened as I started assisting various projects in their launch phases, contributing in creative capacities encompassing marketing, community management, and high-level business operations. My experience as a BCBA has been instrumental, allowing me to apply behavioral analysis techniques across many business aspects. This journey later evolved into self-taught programming, focusing initially on Ethereum and then Solana. The creative process in this domain is something I really love!
                </li>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamInfo;