import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import axios from 'axios';

interface NFT {
    id: string; // Mint address of the NFT
    name: string;
    price: number;
    image: string;
    quantity: number; // Number of NFTs available for sale
}

interface NFTContextProps {
    nfts: NFT[];
    fetchNfts: () => void;
}

interface NFTProviderProps {
    children: ReactNode;
}

const NFTContext = createContext<NFTContextProps | undefined>(undefined);

export const NFTProvider: React.FC<NFTProviderProps> = ({ children }) => {
    const [nfts, setNfts] = useState<NFT[]>([]);

    const fetchNfts = async () => {
        try {
            const response = await axios.get('/nfts-for-sale');
            setNfts(response.data);
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    useEffect(() => {
        fetchNfts();
    }, []);

    return (
        <NFTContext.Provider value={{ nfts, fetchNfts }}>
            {children}
        </NFTContext.Provider>
    );
};

export const useNFTs = () => {
    const context = useContext(NFTContext);
    if (context === undefined) {
        throw new Error('useNFTs must be used within an NFTProvider');
    }
    return context;
};