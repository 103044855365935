import React, { useState } from 'react';
import { chatService } from '../services/api';
import Starfield from './Starfield';
import Avatar2 from './Avatar2';
import ChartTool from './ChartTool';
import JupiterTerminal from './Jupiter';
import emailjs from 'emailjs-com';
import './Tester.css'

interface InputAreaProps {
  toggleLibrary: () => void;
  toggleMenu: () => void;
  setIsLoading: (isLoading: boolean) => void;
  sendMessage: (message: string, imageDataUrl?: string, isRequestForInput?: boolean) => void;
  voiceEnabled: boolean;
  setVoiceEnabled: (enabled: boolean) => void;
  handleFetchTokenData: () => void;
  handleShowChartTool: () => void;
  handleShowMemeChart: () => void;
  stopSpeaking: () => void; // Add this line to the interface
  show: boolean;
}

let currentAudio: HTMLAudioElement | null = null;

const stopSpeaking = () => {
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0;
    currentAudio = null;
    window.dispatchEvent(new Event('stopSpeaking'));
  }
};

const InputTest2: React.FC<InputAreaProps> = ({ toggleLibrary, toggleMenu, setIsLoading, sendMessage, voiceEnabled, setVoiceEnabled, handleFetchTokenData, show, handleShowChartTool, handleShowMemeChart, stopSpeaking }) => {
  const [userInput, setUserInput] = useState('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showBugReportPopup, setShowBugReportPopup] = useState(false);

  const handleSend = () => {
    if (!userInput.trim() && !selectedImage) return;
    setIsLoading(true);
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target?.result as string;
        sendMessage(userInput, imageDataUrl);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      sendMessage(userInput);
    }
    setUserInput('');
    setSelectedImage(null);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleVoiceToggle = () => {
    setVoiceEnabled(!voiceEnabled);
    console.log('Voice Enabled state changed:', !voiceEnabled);
  };

  const toggleHelpPopup = () => {
    setShowHelpPopup(!showHelpPopup);
  };

  const toggleBugReportPopup = () => {
    setShowBugReportPopup(!showBugReportPopup);
  };

  const handleBugReportSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const bugDetails = formData.get('bugDetails') as string;

    const emailParams = {
      message: bugDetails,
    };

    emailjs.send('service_99dfxgd', 'template_qjyvw9w', emailParams, 'QLfO2VmIK7U2VVdIw')
      .then((result) => {
        console.log('Bug report sent:', result.text);
        alert('Bug report sent! We will address it as soon as possible.');
      }, (error) => {
        console.error('Bug report send error:', error.text);
        alert('Failed to send bug report. Please try again later.');
      });

    setShowBugReportPopup(false);
  };

  const handleTokenClick = () => {
    handleFetchTokenData();
    toggleMenu();
  };

  const handleChartClick = () => {
    handleShowChartTool();
    toggleMenu();
  };

  const handleMemeClick = () => {
    handleShowMemeChart();
    toggleMenu();
  };

  return (
    <div className={`input-area3 ${show ? '' : 'hidden'}`}>
      <div className="input-row3">
        <button className="fetch-token-btn glow-effect" onClick={handleTokenClick}>Fetch Token Data</button>
        <button className="chart-tool-btn glow-effect" onClick={handleChartClick}>Charts</button>
        <button className="meme-chart-btn glow-effect" onClick={handleMemeClick}>Meme Charts</button>
        <button 
          className="toggle-library-btn glow-effect" 
          onClick={toggleLibrary}
          onTouchEnd={toggleLibrary}
          >
            Show/Hide Library
            </button>
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          className="hidden"
          onChange={handleImageUpload}
        />
        <button
          className="upload-btn glow-effect"
          onClick={() => document.getElementById('image-upload')?.click()}
        >
          {selectedImage ? 'Image Selected' : 'Coming Soon'}
        </button>
        {selectedImage && <span>{selectedImage.name}</span>}
        <button className="voice-toggle-btn glow-effect" onClick={handleVoiceToggle}>
          Voice Response ({voiceEnabled ? 'ON' : 'OFF'})
        </button>
        <button className="stop-btn glow-effect" onClick={stopSpeaking}>Stop Speaking</button>
        <button className="help-btn glow-effect" onClick={toggleHelpPopup}>Help</button>
        <button className="bug-report-btn glow-effect" onClick={toggleBugReportPopup}>Bug Reporting</button>
        <button
          className="toggle-menu-btn glow-effect"
          onClick={toggleMenu}
          onTouchEnd={toggleMenu} // Handle mobile touch events
        >
          Close Menu
        </button>
        <Avatar2 />
      </div>
      <div className="image-upload3">

      </div>

      {/* Help Popup */}
      {showHelpPopup && (
        <div className="popup-container">
          <div className="popup">
            <h2>Davey Ai User Guide</h2>
            <div>
              <ol>
                <li>
                  <strong>Davey Ai!</strong>
                  <ul>
                    <li>Sending Messages: You can type your messages in the input box at the bottom of the chat window. Just hit “Enter” or click the “Send” button to send your message. Davey is here to help you with a wide range of topics, from coding and troubleshooting to general inquiries.</li>
                    <li>Voice Interaction: You can toggle voice interaction on or off using the “Voice Response” button. When enabled, the AI will respond with voice output.</li>
                  </ul>
                </li>
                <li>
                  <strong>Upload Images (GPT Vision)</strong>
                  <ul>
                    <li>Image Upload: You can upload images by clicking the “Upload Image” button. After selecting a file, enter your text prompt pertaining to the image and then hit submit. The system supports various image formats.</li>
                  </ul>
                </li>
                <li>
                  <strong>Fetch Token Data</strong>
                  <ul>
                    <li>Token Data: Use the “Fetch Token Data” button to retrieve and display token data directly within the chat by pasting in the contract address.</li>
                  </ul>
                </li>
                <li>
                  <strong>Use Chart Tools</strong>
                  <ul>
                    <li>Chart Display: Click the “Charts” or “Meme Charts” button to display chart tools within the chat. You can analyze data visually and interact with various chart types right in the chat window.</li>
                  </ul>
                </li>
                <li>
                  <strong>Access the Conversation Library</strong>
                  <ul>
                    <li>Toggle Library: You can access your previous conversations by clicking the “Toggle Library” button. This feature allows you to revisit old chats and continue where you left off.</li>
                    <li>Chat Deletion/Naming: You can rename or delete chats by opening the conversation library and clicking the edit button to rename chats or clicking the trashcan to delete chats.</li>
                  </ul>
                </li>
                <li>
                  <strong>Get Help and Report Bugs</strong>
                  <ul>
                    <li>Help: If you need assistance, click the “Help” button. A popup will appear with detailed instructions and tips on how to use the system. You can close the popup by clicking the “Close” button.</li>
                    <li>Report Bugs: If you encounter any issues or bugs, click the “Bug Reporting” button. A form will appear where you can describe the problem. After submitting, the details will be sent directly to our support team, and we'll work to resolve the issue as quickly as possible.</li>
                  </ul>
                </li>
                <li>
                  <strong>Stay Updated</strong>
                  <ul>
                    <li>Real-Time Interaction: The system is designed to provide real-time responses and updates, making your experience seamless and efficient. Whether you’re fetching data, uploading files, or interacting with charts, everything happens instantly.</li>
                  </ul>
                </li>
              </ol>
              <p>Enjoy your experience, and feel free to explore all the features available in this advanced chat system! If you need any help, remember, the AI assistant is just a message away.</p>
            </div>
            <button onClick={toggleHelpPopup} className="close-popup-button">Close</button>
          </div>
        </div>
      )}

      {/* Bug Reporting Popup */}
      {showBugReportPopup && (
        <div className="popup-container">
          <div className="popup">
            <h2>Bug Reporting</h2>
            <form onSubmit={handleBugReportSubmit}>
              <textarea name="bugDetails" placeholder="Describe the bug here..." required className="bug-textarea"></textarea>
              <button type="submit" className="submit-bug-button">Submit</button>
              <button onClick={toggleBugReportPopup} type="button" className="close-popup-button">Close</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputTest2;