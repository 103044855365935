import React from 'react';
import styles from '../styles/AlienPage.module.css';

const AlienPage: React.FC = () => {
  return (
    <div className={styles.container}>
        <div className={styles.container2}>
            <img className={styles.mobile} src="/Mobile 2.gif" alt="" />
            <p className={styles.test}>ALIENS!</p>
            <p className={styles.test2}>Utilities</p>
            <ul className={styles.test6}>
            <li >Utilities</li>
            <li >Utilities</li>
            <li >Utilities</li>
            </ul>
            <img className={styles.test3} src="/melogo.png" alt="anybodies" />
            <img className={styles.test4} src="/melogo.png" alt="anybodies" />
            <img className={styles.test5} src="/melogo.png" alt="anybodies" />
        </div>
        
    </div>
  );
};

export default AlienPage;