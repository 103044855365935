
import React from 'react';
import AlertMap from '../components/AlertMap';
import styles from '../styles/Hybrids.module.css'
import Team from './teaminfo';
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const Alerts: React.FC = () => {
  return (
    <>
      <div className={styles.faqcontainer}>
        <div className={styles.hybridcontainer}>
          <AlertMap />
          <p className={styles.faqtitle}>Alerts!</p>
          <div className={styles.alertsbox}>
            <p className={styles.alerttext2}>-Davey Ai beta is live!<Link to="/daveyai" className={styles.alertlink}> Right Here!</Link> </p>
            <p className={styles.alerttext2}>-$MORPH "Quick Info" page added <Link to="/morphdata" className={styles.alertlink}>Right Here!</Link> </p>
            <p className={styles.alerttext2}>-Portfolio page added <Link to="/portfolio" className={styles.alertlink}>Here!</Link> </p>
            <p className={styles.alerttext2}>-NFT filter pages updated! <Link to="/alien" className={styles.alertlink}>Link Here!</Link> </p>
            <p className={styles.alerttext2}>-Governance is live! <Link to="/governance" className={styles.alertlink}>Link!</Link> </p>
            
          </div>
        </div>
      </div>

      <div className={styles.mobilehomecontainer}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <p className={styles.faqtitle2}>Alerts!</p>
        <div className={styles.alertsbox}>
        <p className={styles.alerttext2}>-Davey Ai beta is live!<Link to="/daveyai" className={styles.alertlink}> Right Here!</Link> </p>  
        <p className={styles.alerttext2}>-$MORPH "Quick Info" page added <Link to="/morphdata" className={styles.alertlink}>Right Here!</Link> </p>
        <p className={styles.alerttext2}>-Portfolio page added <Link to="/portfolio" className={styles.alertlink}>Here!</Link> </p>
        <p className={styles.alerttext2}>-NFT filter pages updated! <Link to="/alien" className={styles.alertlink}>Link Here!</Link> </p>
            <p className={styles.alerttext2}>-Governance is live! <Link to="/governance" className={styles.alertlink}>Link!</Link> </p>
        </div>
      </div>


    </>
  );
}

export default Alerts;
