import React, { useEffect, useRef } from 'react';
import './Avatar2.css';

let currentAudio: HTMLAudioElement | null = null;

const Avatar2: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const handleSpeakingStart = (event: CustomEvent) => {
      console.log('Speaking started', event.detail);
      visualize(event.detail);
    };

    const handleSpeakingStop = () => {
      console.log('Speaking stopped');
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        currentAudio = null;
      }
    };

    window.addEventListener('startSpeaking', handleSpeakingStart as EventListener);
    window.addEventListener('stopSpeaking', handleSpeakingStop);

    return () => {
      window.removeEventListener('startSpeaking', handleSpeakingStart as EventListener);
      window.removeEventListener('stopSpeaking', handleSpeakingStop);
    };
  }, []);

  const visualize = (audioUrl: string) => {
    const canvas = canvasRef.current!;
    const canvasCtx = canvas.getContext('2d')!;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    currentAudio = new Audio(audioUrl);
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const source = audioContext.createMediaElementSource(currentAudio);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    currentAudio.play();

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 255, 255)';

      canvasCtx.beginPath();
      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * canvas.height / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  return (
    <div id="avatar">
      <canvas ref={canvasRef} id="waveform"></canvas>
    </div>
  );
};

export default Avatar2;