import React from 'react';

interface DexScreenerEmbedProps {
  contractAddress: string;
  chain: string;
}

const DexScreenerEmbed: React.FC<DexScreenerEmbedProps> = ({ contractAddress, chain }) => {
  const embedUrl = `https://dexscreener.com/${chain}/${contractAddress}?embed=1&theme=dark`;

  return (
    <div>
      <style>
       {`
  #dexscreener-embed {
    position: relative;
    width: 85vw; /* Adjusted the unit from 'dvw' to 'vw' for consistency */
    padding-bottom: 125%;
  }

  @media(min-width: 1400px) {
    #dexscreener-embed {
      padding-bottom: 65%;
    }
  }

  @media(max-width: 768px) { /* Target mobile devices */
    #dexscreener-embed {
      width: 75vw; /* Make width smaller for mobile */
      padding-bottom: 150%; /* Adjust padding for mobile */
    }
  }

  #dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
`}
      </style>
      <div id="dexscreener-embed">
        <iframe src={embedUrl} title="DexScreener Embed"></iframe>
      </div>
    </div>
  );
};

export default DexScreenerEmbed;