import React, { useState, useEffect, useCallback } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import ConversationLibrary from './ConversationLibrary';
import ChatContainer from './ChatContainer';
import InputTest from './InputTest';
import InputTest2 from './InputTest2';
import LoadingIndicator from './LoadingIndicator';
import TokenGate from './TokenGate';
import { chatService } from '../services/api';
import Avatar from './Avatar'; // Import the Avatar component
import './Tester.css';
import Starfield2 from './Starfield2';
import ChartTool from './ChartTool';
import JupiterTerminal from './Jupiter';
import axios from 'axios';
import styles from '../styles/Starfield2.module.css'


interface Message {
    role: 'user' | 'assistant' | 'system';
    content: string;
    isRequestForInput?: boolean;
    imageUrl?: string;
    isChartTool?: boolean;  // Add this new property
}

interface MemeChartMessage extends Message {
    isMemeChart: true;
    contractAddress: string;
    chain: string;
}

const DaveyTest: React.FC = () => {
    const [showLibrary, setShowLibrary] = useState(true);
    const [showMenu, setShowMenu] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [conversationId, setConversationId] = useState('');
    const [voiceEnabled, setVoiceEnabled] = useState(false);
    const [lastUploadedImage, setLastUploadedImage] = useState<string | null>(null);
    const { connected, publicKey } = useWallet();
    const [hasAccess, setHasAccess] = useState(false);
    const [showChartTool, setShowChartTool] = useState(false);
    const [showJupiter, setShowJupiter] = useState(false);
    const customRPC = "https://necessary-virulent-dust.solana-mainnet.quiknode.pro/fc3fc0bfb7498cb577e405ae3c562735778fd315";
    const [showToS, setShowToS] = useState(false);
    const [tosAgreed, setTosAgreed] = useState(false);
    const [chatMessages, setChatMessages] = useState<{ role: string; content: string }[]>([]);
    const [userInput, setUserInput] = useState('');
    const [conversationData, setConversationData] = useState<any>(null);
    const [conversation, setConversation] = useState(null);

    const API_URL = 'https://api.ghsyn.xyz';

    useEffect(() => {
        setConversationId(Date.now().toString());
    }, []);

    useEffect(() => {
        const checkAccess = async () => {
            if (connected && publicKey) {
                try {
                    const access = await chatService.checkAccess(publicKey.toString());
                    setHasAccess(access);
                } catch (error) {
                    console.error('Error checking access:', error);
                    setHasAccess(false);
                }
            } else {
                setHasAccess(false);
            }
        };
        checkAccess();
    }, [connected, publicKey]);

    useEffect(() => {
        console.log('isLoading changed:', isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (publicKey) {
            console.log("Wallet connected, checking ToS agreement");
            checkTosAgreement();
        } else {
            console.log("No wallet connected");
            setShowToS(false);
            setTosAgreed(false);
        }
    }, [publicKey]);

    const checkTosAgreement = async () => {
        if (!publicKey) {
            console.log("No public key available, skipping ToS check");
            return;
        }
        setIsLoading(true);
        try {
            const url = `${API_URL}/api/tos-agreement?wallet_address=${publicKey.toString()}`;
            console.log("Checking ToS agreement at URL:", url);
            const response = await axios.get(url);
            console.log("ToS agreement response:", response.data);
            setTosAgreed(response.data.agreed);
            setShowToS(!response.data.agreed);
        } catch (error) {
            console.error('Error checking ToS agreement:', error);
            setShowToS(true);
        } finally {
            setIsLoading(false);
        }
    };

    console.log("Render state - showToS:", showToS, "tosAgreed:", tosAgreed);

    const handleTosAgree = useCallback(async () => {
        console.log("handleTosAgree called");
        if (!publicKey) {
            console.log("No public key available, can't agree to ToS");
            return;
        }
        setIsLoading(true);
        try {
            const url = `${API_URL}/api/tos-agreement?wallet_address=${publicKey.toString()}`;
            console.log("Submitting ToS agreement to URL:", url);
            const response = await axios.post(url);
            console.log("ToS agreement submission response:", response.data);
            setTosAgreed(true);
            setShowToS(false);
        } catch (error) {
            console.error('Error submitting ToS agreement:', error);
        } finally {
            setIsLoading(false);
        }
    }, [publicKey]);

    const handleSendMessage = async () => {
        if (!publicKey || !userInput.trim()) return;

        setIsLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/chat`, {
                message: userInput,
                wallet_address: publicKey.toString()
            });
            setChatMessages([...chatMessages, { role: 'user', content: userInput }, { role: 'assistant', content: response.data.response }]);
            setUserInput('');
        } catch (error) {
            console.error('Error sending message:', error);
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                console.log("User has not agreed to ToS. Showing ToS overlay.");
                setShowToS(true);
                setTosAgreed(false);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const addMessage = (message: Message) => {
        setMessages(prevMessages => [...prevMessages, message]);
    };


    const handleShowChartTool = () => {
        setShowChartTool(true);
        addMessage({ role: 'system', content: 'Chart Tool', isChartTool: true });
    };

    const handleShowJupiter = () => {
        setShowJupiter(prev => !prev);
    };

    const sendMessage = async (input: string, imageDataUrl?: string) => {
        if (!input.trim() && !imageDataUrl) return;
        if (!publicKey) {
            console.error("Wallet not connected");
            return;
        }

        setIsLoading(true);
        try {
            let messageContent;
            if (imageDataUrl) {
                messageContent = [
                    { type: 'text', text: input },
                    { type: 'image_url', image_url: { url: imageDataUrl } }
                ];
                setLastUploadedImage(imageDataUrl);
            } else {
                messageContent = [{ type: 'text', text: input }];
            }

            const response = await chatService.sendMessage(messageContent, conversationId, publicKey.toString());

            const newMessages: Message[] = [
                {
                    role: 'user',
                    content: input,
                    imageUrl: imageDataUrl
                },
                { role: 'assistant', content: response.response }
            ];
            setMessages(prevMessages => [...prevMessages, ...newMessages]);

            if (voiceEnabled) {
                await speakMessage(response.response);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            addMessage({ role: 'assistant', content: "An error occurred while processing your message. Please try again or refresh the page." });
        } finally {
            setIsLoading(false);
        }
    };

    let currentAudio: HTMLAudioElement | null = null;




    const handleShowMemeChart = () => {
        addMessage({
            role: 'system',
            content: 'Please enter the contract address and select the chain for the meme chart:',
            isMemeChart: true
        } as Message);
    };

    const handleMemeChartSubmit = (contractAddress: string, chain: string) => {
        addMessage({
            role: 'system',
            content: 'Meme Chart',
            isMemeChart: true,
            contractAddress,
            chain
        } as MemeChartMessage);
    };

    const stopSpeaking = () => {
        window.dispatchEvent(new Event('stopSpeaking'));
    };

    const speakMessage = async (text: string) => {
        try {
            if (currentAudio) {
                stopSpeaking();  // Ensure to stop any ongoing speech
            }

            const audioBlob = await chatService.textToSpeech(text);
            const url = window.URL.createObjectURL(audioBlob);
            currentAudio = new Audio(url);

            const event = new CustomEvent('startSpeaking', { detail: url });
            window.dispatchEvent(event);

            currentAudio.onended = () => {
                window.dispatchEvent(new Event('stopSpeaking'));
                currentAudio = null;
            };

        } catch (error) {
            console.error('Error in text-to-speech:', error);
        }
    };

    const toggleLibrary = () => {
        setShowLibrary(!showLibrary);
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const startNewChat = () => {
        const newConversationId = Date.now().toString();
        setConversationId(newConversationId);
        setMessages([]);
    };

    const loadConversation = async (id: string) => {
        setIsLoading(true);
        try {
            if (publicKey) {
                const walletAddress = publicKey.toString();
                const conversation = await chatService.getConversation(id, walletAddress);
                setMessages(conversation.map((msg: any) => ({
                    role: msg.role,
                    content: msg.content,
                    imageUrl: msg.imageUrl
                })));
                setConversationId(id);
            }
        } catch (error) {
            console.error('Error loading conversation:', error);
            addMessage({ role: 'system', content: 'Error loading conversation. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFetchTokenData = () => {
        addMessage({ role: 'system', content: 'Please enter the contract address:', isRequestForInput: true });
    };

    const fetchTokenData = async (contractAddress: string) => {
        setIsLoading(true);
        try {
            const tokenData = await chatService.fetchTokenData(contractAddress);
            const formattedData = formatTokenData(tokenData);
            addMessage({ role: 'assistant', content: formattedData });
        } catch (error) {
            console.error("Error fetching token data:", error);
            addMessage({ role: 'assistant', content: "Error fetching token data. Please try again." });
        } finally {
            setIsLoading(false);
        }
    };

    const formatTokenData = (tokenData: any) => {
        return `
      <p><strong>Token Data:</strong></p>
      <p>Name: ${tokenData.name}</p>
      <p>Price: $${tokenData.price.toFixed(13)}</p>
      <p>24h Volume: $${tokenData.volume24h.toFixed(2)}</p>
      <p>Liquidity (USD): $${tokenData.liquidityUsd.toFixed(2)}</p>
      <p>24h Price Change: ${tokenData.priceChange24h.toFixed(2)}%</p>
      <p>Market Cap: $${tokenData.marketCap.toFixed(2)}</p>
    `;
    };

    const handleContractAddressSubmit = (contractAddress: string) => {
        addMessage({ role: 'user', content: contractAddress });
        fetchTokenData(contractAddress);
    };

    return (
        <div className="ai-page">
            <Starfield2 />
            <div className="ai-interface">
                <header className="ai-header">
                    <h1>Davey Ai Beta</h1>
                    <WalletMultiButton />
                </header>
                <main>
                {connected ? (
                hasAccess ? (
                        <div className="ai-interface2">
                            <div className="animated-bg"></div>
                            <div className="content-wrapper">
                                <ConversationLibrary
                                    show={showLibrary}
                                    startNewChat={startNewChat}
                                    loadConversation={loadConversation}
                                />
                                <div className="main-content">
                                    <LoadingIndicator isLoading={isLoading} />
                                    <ChatContainer
                                        messages={messages}
                                        onContractAddressSubmit={handleContractAddressSubmit}
                                        onMemeChartSubmit={handleMemeChartSubmit}
                                        showJupiter={showJupiter}
                                    />
                                    
                                </div>
                                
                            </div>
                            <div className="bottombar">
                                    <InputTest
                                        setIsLoading={setIsLoading}
                                        sendMessage={sendMessage}
                                        showMenu={showMenu}   // Pass showMenu state to InputTest
                                        toggleMenu={toggleMenu}  // Pass toggleMenu function to InputTest
                                    />
                                    
                                </div>
                            <div className="sidebar">
                                <InputTest2
                                     show={showMenu}
                                     toggleLibrary={toggleLibrary}
                                     setIsLoading={setIsLoading}
                                     sendMessage={sendMessage}
                                     voiceEnabled={voiceEnabled}
                                     setVoiceEnabled={setVoiceEnabled}
                                     handleFetchTokenData={handleFetchTokenData}
                                     handleShowChartTool={handleShowChartTool}
                                     handleShowMemeChart={handleShowMemeChart}
                                     stopSpeaking={stopSpeaking}
                                     toggleMenu={toggleMenu}
                                />
                            </div>
                        </div>
                    ) : (
                      <div className="connect-prompt">
                      <p>You do not have access to Davey Ai. Gain access by obtaining an Alien NFT or at least 11 bil $MORPH</p>
                    </div>
                     )
                    ) : (
                      <div className="connect-prompt">
                        <p>Please connect your wallet to access Davey Ai.</p>
                      </div>
                    )}
                </main>
            </div>
        </div>
    );
};

export default DaveyTest;