import React from 'react';
import MorphMap from '../components/MorphMap';
import Tokenomics from './tokenomics';
import styles from '../styles/Hybrids.module.css';
import Port from './port';
import AlienMap2 from '../components/AlienMap2';
import Data from './data';

const MorphData: React.FC = () => {
  return (
    <>
    <div className={styles.container3}>
      <MorphMap />
      <div className={styles.overlay4}>
        <Data />
      </div>
    </div>

    <div className={styles.mobilehomecontainer2}>
        <AlienMap2/>
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <div className={styles.overlay4}>
        <Data />
        </div>
      </div>

    </>
  );
}

export default MorphData;