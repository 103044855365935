import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../components/SolBalance.module.css';

const SolBalance: React.FC = () => {
    const [balance, setBalance] = useState<number | null>(null);

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                console.log('Fetching balance...');
                const response = await axios.get('https://api.ghsyn.xyz/balance');
                console.log('Response:', response);
                setBalance(response.data.balance);
            } catch (error) {
                console.error('Failed to fetch balance:', error);
            }
        };
        fetchBalance();
    }, []);

    return (
        <div className={styles.balanceContainer}>
            <h2>Galactic Governance Treasury Balance:</h2>
            <p>{balance !== null ? balance.toFixed(3) : 'Loading...'} SOL</p>
        </div>
    );
};

export default SolBalance;