import { Helmet } from 'react-helmet';
import styles from '../styles/Tokenomics.module.css';
import SupplyPieChart from '../components/Chart';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


export default function Tokenomics() {

  // Data for Chart.js
  const data = {
    labels: [
      'Supply to LP (55%)',
      'Supply for Operations (20%)',
      'Supply for Holder Airdrops (10%)',
      'Supply for Staking (10%)',
      'Team Supply (5%)'
    ],
    datasets: [{
      data: [23331280500000, 8484102000000, 4242051000000, 4242051000000, 2121025500000], // Supply amounts
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1
    }]
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        color: '#FFF',
        font: {
          weight: 'bold' as const
        },
        formatter: (value: number, context: any) => {
          return `${value}%`;
        }
      },
      legend: {
        labels: {
          color: 'white',
          font: {
            size: 15
          }
        }
      }
    }
  };



  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.overviewSection2}>
            <h1 className={styles.title}>$MORPH TOKENOMICS</h1>
            <img className={styles.heroimage} src="/images/morphtoken.png" />
            <div className={styles.tokelinks}>
            <a className={styles.swap3} href="https://jup.ag/swap/SOL-ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj"
              target="_blank"
              rel="noopener noreferrer"><strong>
              JUPITER SWAP</strong>
            </a>
            <Link className={styles.swap3} to="/morphdata"><strong>QUICK INFO</strong></Link>
            <Link className={styles.swap3} to="/portfolio"><strong>PORTFOLIO</strong></Link>
            <Link className={styles.swap6} to="/"><strong>GHS HOME</strong></Link>
            </div>
            <p className={styles.swap2}>Scroll Down Hooman!</p>
          </div>
        </div>

        {/* Sections with visual enhancements */}
        <div className={styles.content}>
          {/* Overview Section */}
          <section className={styles.overviewSection}>
            <h2 className={styles.sectionHeading}>Overview</h2>
            <div className={styles.overviewContent}>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Initial Supply:</strong> 42,420,510,000,000 (Trillion). Supply is deflationary with multiple mechanisms to trigger burns of tokens. No new tokens will be minted in the future. <strong>NO PRESALE</strong></p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Circulating Supply:</strong> 41,656,942,874,202</p>
                <p className={styles.overviewText}><strong>Total Burnt:</strong> 763,567,125,798 billion (1.8% of Supply)</p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Token Address:</strong> <a href="https://explorer.solana.com/address/ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj" target="_blank" rel="noopener noreferrer" className={styles.link}>ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj</a></p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>LP Address:</strong> <a href="https://explorer.solana.com/address/9udeGNjHGU8xCUpJcn14p18C6vSNfWyBZwGZL9Fz8J7L" target="_blank" rel="noopener noreferrer" className={styles.link}>9udeGNjHGU8xCUpJcn14p18C6vSNfWyBZwGZL9Fz8J7L</a></p>
                <p className={styles.overviewText}><strong>LP Tokens Burnt Here:</strong> <a href="https://solscan.io/tx/4xQfc2y1v7qGK2MyhWdkdcZ9JfxRxRD1i7WTu3uzDUBUFj3wXbFjkC5sMS7T7m3TLUZicWR6UDawrYdeCtBwjV3z" target="_blank" rel="noopener noreferrer" className={styles.link}>Burn Transaction</a></p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Mint and Freeze Authority Revoked</strong></p>
                <p className={styles.overviewText}><strong>Fee Update Authority Revoked</strong></p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Token Type:</strong> Token2022 (Token Extensions)</p>
                <p className={styles.overviewText}><strong>Tax:</strong> 1% on all Buys/Sells/Transfers</p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Operations Address:</strong> <a href="https://solscan.io/account/F2PanitgTsURkmCawp1gcWdDRsKsCdqRdDSwocihrKtp" target="_blank" rel="noopener noreferrer" className={styles.link}>F2PanitgTsURkmCawp1gcWdDRsKsCdqRdDSwocihrKtp</a></p>
              </div>
              <div className={styles.overviewItem}>
                <p className={styles.overviewText}><strong>Hot Wallet Address (SagaAliens.sol):</strong> <a href="https://solscan.io/account/mW1XMXgfVyqNucTuRfeJJVXv7r968HBjy2XRxSLveXK" target="_blank" rel="noopener noreferrer" className={styles.link}>mW1XMXgfVyqNucTuRfeJJVXv7r968HBjy2XRxSLveXK</a></p>
              </div>
            </div>
          </section>


          {/* Allocation Section */}
          <section className={styles.section}>
            <h2 className={styles.sectionHeading}>Allocation</h2>
            <div className={styles.chartContainer}>
              <Pie data={data} options={options} />
            </div>
          </section>

          {/* Distribution/Earning Methods Section */}
          <section className={styles.distributionSection}>
            <h2 className={styles.sectionHeading}>Distribution</h2>
            <div className={styles.distributionContent}>
              <div className={styles.distributionItem}>
                <p className={styles.distributionText}><strong>SAGA ALIENS holders</strong> will receive their portion of the supply in 3 separate airdrops. The first airdrop will be on January 26th, the second on February 9th, and the last on February 23rd of 2024.</p>
                <p className={styles.distributionText}><strong>Qualifications</strong> Must have unlisted and/or staked SAGA ALIENS in your wallet at the time of the snapshot. Snapshot times will be announced on X and in Discord. </p>
              </div>
              <div className={styles.distributionItem}>
                <p className={styles.distributionText}><strong>Snapshots</strong> will be taken of holdings prior to each airdrop. Holders' total $MORPH allotment will be based on the total number of SAGA ALIENS they hold and/or stake at the time of the snapshot.</p>
              </div>
              <div className={styles.distributionItem}>
                <p className={styles.distributionText}><strong>Staking:</strong> SAGA ALIENS and SAGA ALIENS 1/1s holders are able to stake for $MORPH</p>
                <p className={styles.distributionText}><strong>SAGA ALIENS:</strong> 80m $MORPH per day</p>
                <p className={styles.distributionText}><strong>SAGA ALIENS 1/1s:</strong> 120m $MORPH per day</p>
              </div>
              <div className={styles.distributionItem}>
                <p className={styles.distributionText}><strong>Future staking opportunities</strong> will occur as well after the 10% of the supply that has been dedicated to staking is claimed. We additionally are adding to the staking pool over time. So far an additional 505b $MORPH has been added to the pool. Future collections will be stakeable as well.</p>
              </div>
              <div className={styles.distributionItem}>
                <p className={styles.distributionText}>Portions of the operational budget and income from tax will be utilized to provide additional distribution opportunities for $MORPH holders. Holders will have multiple ways to access extra $MORPH. Some examples include engagement on Discord and X, completing tasks through Zealy, creating 1/1s for our inscriptions collection, engaging in competitions, and more! This list will be constantly expanding so that we can spread $MORPH around to our holders in a variety of exciting ways!</p>
              </div>
              {/* ... More items if there are any ... */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

