// src/overrides/ledgerhq-errors.js

module.exports = {
  TransportError: class extends Error {},
  DisconnectedDeviceDuringOperation: class extends Error {},
  DisconnectedDevice: class extends Error {},
  TransportOpenUserCancelled: class extends Error {},
  StatusCodes: {},
  getAltStatusMessage: () => {},
  TransportRaceCondition: class extends Error {}, // Add this line
};
