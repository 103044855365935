import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import ChartTool from './ChartTool';
import MemeChartTool from './MemeChartTool';
import DexScreenerEmbed from './DexScreenerEmbed';
import JupiterTerminal from './Jupiter';
import './ChatContainer.css';
import { useState } from 'react';



interface Message {
  role: 'user' | 'assistant' | 'system';
  content: string;
  isRequestForInput?: boolean;
  imageUrl?: string;
  isChartTool?: boolean;
}

interface MemeChartMessage extends Message {
  isMemeChart: true;
  contractAddress?: string;
  chain?: string;
}

interface ChartToolMessage extends Message {
  symbol: string;
  assetType: 'stock' | 'crypto';
}

interface ChatContainerProps {
  messages: (Message | ChartToolMessage | MemeChartMessage)[];
  onContractAddressSubmit: (contractAddress: string) => void;
  onMemeChartSubmit: (contractAddress: string, chain: string) => void;
  showJupiter: boolean;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ messages, onContractAddressSubmit, onMemeChartSubmit, showJupiter }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showBugReportPopup, setShowBugReportPopup] = useState(false);

  const toggleHelpPopup = () => {
    setShowHelpPopup(!showHelpPopup);
  };

  const toggleBugReportPopup = () => {
    setShowBugReportPopup(!showBugReportPopup);
  };

  const handleBugReportSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const bugDetails = formData.get('bugDetails') as string;
    // Logic to send email with bugDetails here
    console.log('Bug reported:', bugDetails);
    setShowBugReportPopup(false); // Close popup after submission
  };

  // Function to handle content rendering
  const renderContent = (content: string) => {
    if (content.startsWith("[Image URL:")) {
      return null; // Skip rendering the image URL string
    } else {
      return parse(content);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const contractAddress = (event.target as any).contractAddress.value;
    onContractAddressSubmit(contractAddress);
  };

  return (
    <div className="flex-1 overflow-y-auto p-4 chat-container" id="chat-container" ref={chatContainerRef}>
      {messages.map((message, index) => {
        const content = renderContent(message.content);
        return content && (
          <div key={index} className={`message ${message.role}`}>
            <div className="message-content">
              <strong>{message.role === 'user' ? '' : 'Davey:'}</strong>
              {message.isRequestForInput ? (
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="contractAddress"
                    className="w-full p-2 bg-gray-800 text-white rounded mt-2"
                    placeholder="Enter Contract Address"
                  />
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                  >
                    Submit
                  </button>
                </form>
              ) : message.isChartTool ? (
                <div className="chart-tool-container">
                  <h3>Chart Tool</h3>
                  <ChartTool 
                    symbol={(message as ChartToolMessage).symbol} 
                    assetType={(message as ChartToolMessage).assetType}
                  />
                </div>
              ) : (message as MemeChartMessage).isMemeChart ? (
                <div className="meme-chart-container">
                  <h3>Happy Charting Degen!</h3>
                  {(message as MemeChartMessage).contractAddress ? (
                    <DexScreenerEmbed 
                      contractAddress={(message as MemeChartMessage).contractAddress!}
                      chain={(message as MemeChartMessage).chain!}
                    />
                  ) : (
                    <MemeChartTool onSubmit={onMemeChartSubmit} />
                  )}
                </div>
              ) : (
                <div className="message-content">
                  {message.imageUrl && (
                    <img 
                      src={message.imageUrl} 
                      alt="Uploaded" 
                      className="max-w-xs max-h-48 object-contain mb-2 rounded shadow-md" 
                    />
                  )}
                  {content} {/* Use renderContent function here */}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatContainer;