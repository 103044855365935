// components/ImageGallery.tsx
import React from 'react';
import { NFT } from './types';
import { useInView } from 'react-intersection-observer';
import FilterMapA from './FilterMapA';

interface ImageGalleryProps {
  nfts: NFT[];
  onNFTClick: (nft: NFT) => void; // Function to handle when an NFT is clicked
}

const ImageGallery3M: React.FC<ImageGalleryProps> = ({ nfts, onNFTClick }) => {
  return (
    <div style={{ width: '55%', display: 'flex', overflowY: 'auto', height: 'calc(66dvh - 80px)', flexWrap: 'wrap', justifyContent: 'flex-start', position: 'fixed', top: '22%', left: '39%', zIndex:12 }}>
      {nfts.map(nft => (
        <div key={nft.name} style={{ marginLeft:'18px', width: 'calc(32% - 1px)', cursor: 'pointer' }}
          onClick={() => onNFTClick(nft)}>
          {nft.image && <img src={`/collections/ALIENS/${nft.image}`} alt={nft.name} style={{ width: '100%' }} />}
          <p style={{ color: 'white', fontSize:'2.25dvw', marginLeft:'0px', marginTop: '0dvw' }}>{nft.name}</p>
        </div>
      ))}
    </div>
  );
};

const LazyImage: React.FC<{ src: string; alt: string; name: string; onClick: (event: React.MouseEvent<HTMLDivElement>) => void }> = ({ src, alt, name, onClick }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <div ref={ref} style={{ margin: '10px', width: 'calc(25% - 20px)', cursor: 'pointer' }} onClick={onClick}>
      {inView ? (
        <img src={src} alt={alt} style={{ width: '100%', marginBottom: '8px' }} />
      ) : (
        <div style={{ height: '300px', background: '#eee', marginBottom: '8px' }} />
      )}
      <p style={{ color: 'white' }}>{name}</p>
    </div>
    
  );
};



export default ImageGallery3M;
