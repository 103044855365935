import React from 'react';
import { NFT } from './types'; // Ensure this path is correct.
import styles from '../styles/NFTmodalM.module.css'

interface NFTModalProps {
  nft: NFT;
  onClose: () => void;
}

const NFTModal2M: React.FC<NFTModalProps> = ({ nft, onClose }) => {
  const magicEdenLink = `https://magiceden.io/item-details/${nft.tokenAddress}?name=HOOMANS-%23${nft.number}`;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.modalLeft}>
          <img src={`/collections/ALIENS/${nft.image}`} alt={nft.name} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', marginBottom: '0px' }} />
        </div>
        <div className={styles.modalRight}>
          <h2 className={styles.nftname2}>{nft.name}</h2>
          <div className={styles.gridStyle}>
            {nft.attributes.map((attr, index) => (
              <div key={index} className={styles.attribute}>
                <strong>{attr.trait_type}</strong>: {attr.value}
              </div>
            ))}
          </div>
          
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default NFTModal2M;