import React, { useEffect, useState } from 'react';
import { Connection, PublicKey, ParsedAccountData } from '@solana/web3.js';
import axios from 'axios';
import './data.css';
import { Link } from 'react-router-dom';

const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';
const TOKEN_MINT_ADDRESS = 'ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj';
const TOKEN_PROGRAM_ID = 'TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb'; // Token program ID for Token 2022
const DEX_SCREENER_API_URL = 'https://api.dexscreener.com/latest/dex/tokens/';

const Data: React.FC = () => {
  const [circulatingSupply, setCirculatingSupply] = useState<number | null>(null);
  const [holderCount, setHolderCount] = useState<number | null>(null);
  const [tokenPriceUSD, setTokenPriceUSD] = useState<number | null>(null);
  const [tokenPriceSOL, setTokenPriceSOL] = useState<number | null>(null);
  const [marketCap, setMarketCap] = useState<number | null>(null);

  useEffect(() => {
    const connection = new Connection(SOLANA_RPC_ENDPOINT);

    const fetchTokenData = async () => {
      try {
        // Fetch circulating supply
        const supplyResponse = await connection.getTokenSupply(new PublicKey(TOKEN_MINT_ADDRESS));
        const circulatingSupply = supplyResponse.value.uiAmount; // Adjust according to the actual decimals of $MORPH
        setCirculatingSupply(circulatingSupply);

        // Fetch all token accounts
        const tokenAccounts = await connection.getParsedProgramAccounts(
          new PublicKey(TOKEN_PROGRAM_ID),
          {
            filters: [
              {
                memcmp: {
                  offset: 0,
                  bytes: TOKEN_MINT_ADDRESS,
                },
              },
            ],
          }
        );

        // Filter out accounts with a non-zero balance and get unique holders
        const uniqueHolders = new Set<string>();
        tokenAccounts.forEach(account => {
          const parsedData = account.account.data as ParsedAccountData;
          if (parsedData.parsed.type === 'account') {
            const tokenAmount = parsedData.parsed.info.tokenAmount.uiAmount;
            if (tokenAmount > 0) {
              uniqueHolders.add(parsedData.parsed.info.owner);
            }
          }
        });

        setHolderCount(uniqueHolders.size);

        // Fetch token price from Dexscreener
        const response = await axios.get(`${DEX_SCREENER_API_URL}${TOKEN_MINT_ADDRESS}`);
        const priceUSD = parseFloat(response.data.pairs[0].priceUsd);
        const priceSOL = parseFloat(response.data.pairs[0].priceNative); // Assuming this returns the price in SOL

        setTokenPriceUSD(priceUSD);
        setTokenPriceSOL(priceSOL);

        const marketCap = Math.floor(circulatingSupply! * priceUSD);
        setMarketCap(marketCap);

      } catch (error) {
        console.error('Failed to get token data:', error);
      }
    };

    fetchTokenData();
  }, []);

  const maxSupply = 42420510000000;
  const burnedTokens = circulatingSupply !== null ? maxSupply - circulatingSupply : 'Loading...';

  return (
    <>
      <div className="datawrapper">
      <div className="morph-data-page">
        <h1>$MORPH QUICK INFO</h1>
        <h2><i>(Updated in Real Time!)</i></h2>
        <div className="morph-data-container">
          <div className="data-box">
            <h3>Token Symbol</h3>
            <p>MORPH</p>
          </div>
          <div className="data-box">
            <h3>Contract Owner</h3>
            <p>Renounced</p>
          </div>
          <div className="data-box">
            <h3>Solscan Link</h3>
            <a href="https://solscan.io/token/ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj" className="link-item4">Click Here</a>
          </div>
          <div className="data-box">
            <h3>LP Burn Tx</h3>
            <a href="https://solscan.io/tx/4xQfc2y1v7qGK2MyhWdkdcZ9JfxRxRD1i7WTu3uzDUBUFj3wXbFjkC5sMS7T7m3TLUZicWR6UDawrYdeCtBwjV3z" className="link-item4">Click Here</a>
          </div>
          <div className="data-box">
            <h3>Metadata Type</h3>
            <p>Mutable</p>
          </div>
          <div className="data-box">
            <h3>Max Supply</h3>
            <p>{maxSupply.toLocaleString()}</p>
          </div>
          <div className="data-box">
            <h3>Circulating Supply</h3>
            <p>{circulatingSupply !== null ? circulatingSupply.toLocaleString() : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>$MORPH Burned</h3>
            <p>{burnedTokens.toLocaleString()}</p>
          </div>
          <div className="data-box">
            <h3>Current Price in SOL</h3>
            <p>{tokenPriceSOL !== null ? tokenPriceSOL.toFixed(15) : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Current Price in USD</h3>
            <p>${tokenPriceUSD !== null ? tokenPriceUSD.toFixed(13) : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Market Cap</h3>
            <p>${marketCap !== null ? marketCap.toLocaleString() : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Holder Count</h3>
            <p>{holderCount !== null ? holderCount.toLocaleString() : 'Loading...'}</p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Data;