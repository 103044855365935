import React, { useState, useEffect } from 'react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios';
import styles from '../styles/TraitStore.module.css';

// Solana RPC endpoint URL
const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';

const TraitStore: React.FC = () => {
    // Access wallet information using the Solana wallet adapter
    const { publicKey, sendTransaction } = useWallet();

    // State variables for storing NFTs, selected NFT, messages, and transaction signatures
    const [nfts, setNfts] = useState<any[]>([]);
    const [selectedNft, setSelectedNft] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [transactionSignature, setTransactionSignature] = useState<string | null>(null);
    const [nftTransferSignature, setNftTransferSignature] = useState<string | null>(null);

    // Fetch NFTs available for sale when the component mounts
    useEffect(() => {
        const fetchNfts = async () => {
            try {
                const response = await axios.get('http://localhost:3001/nfts-for-sale');
                setNfts(response.data);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        };

        fetchNfts();
    }, []);

    // Handle the process of buying an NFT
    const handleBuy = async () => {
        if (!publicKey || !selectedNft) {
            setMessage('Please connect your wallet and select an NFT');
            return;
        }

        try {
            // Step 1: Request to create the $MORPH transaction
            const response = await axios.post('http://localhost:3001/create-morph-transaction', {
                buyerPublicKey: publicKey.toString(),
                nftMintAddress: selectedNft
            });

            console.log('Transaction response:', response.data);

            // Step 2: Deserialize the transaction
            const transaction = Transaction.from(Buffer.from(response.data.transaction, 'base64'));
            console.log('Deserialized transaction:', transaction);

            // Step 3: Send the transaction for signing
            const signature = await sendTransaction(transaction, new Connection(SOLANA_RPC_ENDPOINT, 'confirmed'));
            console.log('Transaction signature:', signature);

            // Step 4: Confirm the transaction on the server
            const confirmResponse = await axios.post('http://localhost:3001/confirm-morph-transaction', {
                signedTransaction: transaction.serialize().toString('base64'),
                nftMintAddress: selectedNft
            });

            console.log('Confirm transaction response:', confirmResponse.data);

            // Update state with the transaction signature and confirmation message
            setTransactionSignature(signature);
            setMessage(`Transaction successful! Payment Tx Signature: ${signature}`);
            setNftTransferSignature(confirmResponse.data.nftTransferSignature);
        } catch (error: any) {
            console.error('Transaction failed:', error);
            setMessage(`Transaction failed. ${error.message || 'Please try again.'}`);
        }
    };

    return (
        <div className={styles.traitstorecontainer}>
            {/* Wallet connection button */}
            <WalletMultiButton />
            
            {/* List of NFTs available for sale */}
            <div className={styles.nftlist}>
                {nfts.map(nft => (
                    <div key={nft.id} className={`${styles.nftitem} ${selectedNft === nft.id ? styles.selected : ''}`} onClick={() => setSelectedNft(nft.id)}>
                        <img className={styles.nftimage} src={nft.image} alt={nft.name} />
                        <h3>{nft.name}</h3>
                        <p>Price: {nft.price} $MORPH</p>
                    </div>
                ))}
            </div>

            {/* Button to initiate the buy process */}
            <button onClick={handleBuy}>Buy NFT</button>

            {/* Display messages */}
            {message && <p>{message}</p>}

            {/* Display transaction details if available */}
            {transactionSignature && (
                <div className={styles.transactiondetails}>
                    <h4>Transaction Signature:</h4>
                    <p>{transactionSignature}</p>
                    <a href={`https://solscan.io/tx/${transactionSignature}`} target="_blank" rel="noopener noreferrer">
                        View on Solscan
                    </a>
                </div>
            )}
            {nftTransferSignature && (
                <div className={styles.transactiondetails}>
                    <h4>NFT Transfer Signature:</h4>
                    <p>{nftTransferSignature}</p>
                    <a href={`https://solscan.io/tx/${nftTransferSignature}`} target="_blank" rel="noopener noreferrer">
                        View on Solscan
                    </a>
                </div>
            )}
        </div>
    );
};

export default TraitStore;
