import React from 'react';
import HybridMap from '../components/HybridMap';
import styles from '../styles/Hybrids.module.css';
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const Hybrids: React.FC = () => {
  return (
    <>
    <div className={styles.containerhybrid}>
      <div className={styles.hybridcontainer}>
        <HybridMap />
        <p className={styles.hybridtitle}>Hybrids</p>
        <p className={styles.hybridtext}>Coming Soon!</p>
        <p className={styles.hybridtext1}>Combine $MORPH with Aliens + Hoomans to claim hybrids!</p>
      </div>
    </div>
    <div className={styles.mobilehomecontainer}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <div className={styles.container2}>
          <p className={styles.alientitle}>HYBRIDS!</p>
          <p className={styles.subtitle}>WEN SIR? HOW SIR?</p>
          <ul className={styles.subtexts}>
            <li >-HOLD ALIENS</li>
            <li >-HOLD HOOMANS</li>
            <li >-HOLD $MORPH</li>
            <li >-BURN ALIENS, HOOMANS, AND $MORPH</li>
            <li >-MAKE DAVEY HAPPY</li>
            <li >-MORE $MORPH</li>
            <li >-WEN? SOON!</li>
          </ul>
        </div>
      </div>
    
    </>
  );
}

export default Hybrids;