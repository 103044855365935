import React from 'react';
import CollapsibleM from './CollapsibleM';
import { Filter } from './types';

interface FilterSidebarProps {
  filters: Filter;
  selectedFilters: Filter;
  onFilterChange: (trait_type: string, value: string, isChecked: boolean) => void;
  traitCounts: { [key: string]: number };
  valueCounts: { [key: string]: { [key: string]: number } };
}

const FilterSidebar2M: React.FC<FilterSidebarProps> = ({ filters, selectedFilters, onFilterChange, traitCounts, valueCounts }) => {
  return (
    <div style={{ marginLeft:'14.5%', width: '22%', padding: '20px', overflowY: 'auto', color: 'white', height: 'calc(60.75dvh - 80px)', position: 'fixed', top: '22%', zIndex:12 }}>
      {Object.keys(filters).map(trait_type => (
        <CollapsibleM key={trait_type} title={trait_type} count={traitCounts[trait_type]}>
          {filters[trait_type].map(value => (
            <label key={value} style={{ display: 'block', marginTop: '2dvw', fontSize:'2.5dvw', textAlign: 'left' }}>
              <input
                type="checkbox"
                checked={selectedFilters[trait_type]?.includes(value)}
                onChange={(e) => onFilterChange(trait_type, value, e.target.checked)}
              />
              {value} ({valueCounts[trait_type][value]})
            </label>
          ))}
        </CollapsibleM>
      ))}
    </div>
  );
};

export default FilterSidebar2M;