import React from 'react';
import MorphMap from '../components/MorphMap';
import Tokenomics from './tokenomics';
import styles from '../styles/Hybrids.module.css';
import AlienMap2 from '../components/AlienMap2';

const Morph: React.FC = () => {
  return (
    <>
    <div className={styles.container3}>
      <MorphMap />
      <div className={styles.overlay}>
        <Tokenomics />
      </div>
    </div>

    <div className={styles.mobilecontainer}>
        <AlienMap2/>
        <Tokenomics />
      </div>

    </>
  );
}

export default Morph;