import React, { useEffect, useState } from 'react';
import styles from '../styles/Hybrids.module.css';
import { useNavigate } from 'react-router-dom';
import Starfield from '../components/Starfield';
import 'process/browser';

const Welcome: React.FC = () => {
    const [displayedText1, setDisplayedText1] = useState('');
    const [displayedText2, setDisplayedText2] = useState('');
    const [displayedText3, setDisplayedText3] = useState('');
    const [buttonVisible, setButtonVisible] = useState(true);
    const [beamingUp, setBeamingUp] = useState(false);
    const navigate = useNavigate();
    const fullText1 = 'Where did I put that damn MORPH?!';
    const fullText2 = 'Oh... hello there, Hooman! Davey is the name. Fearsome leader of the Galactic Heist Syndicate, former general of the Saga Aliens, universal MORPH chugging champion, and famous captor-er, I mean proud dad of Steve.';
    const fullText3 = 'Ready for an adventure? Climb aboard, you filthy Hooman!';
    
    useEffect(() => {
        const animateText = (text: string, setText: React.Dispatch<React.SetStateAction<string>>, delay: number = 0) => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    let currentIndex = 0;
                    const intervalId = setInterval(() => {
                        setText(text.substring(0, currentIndex + 1));
                        currentIndex++;
                        if (currentIndex === text.length) {
                            clearInterval(intervalId);
                            resolve();
                        }
                    }, 30);
                }, delay);
            });
        };

        const sequence = async () => {
            await animateText(fullText1, setDisplayedText1);
            await animateText(fullText2, setDisplayedText2, 500);
            await animateText(fullText3, setDisplayedText3, 500);
        };

        sequence();
    }, []);

    const handleButtonClick = () => {
        const audio = new Audio('/sounds/enter.mp3');
        audio.play().catch((error) => {
            console.error('Failed to play audio:', error);
        });
        setBeamingUp(true);
        setTimeout(() => {
            navigate('/mothership');
        }, 1000); // Navigate after 1 second (duration of the animation)
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setButtonVisible((prev) => !prev);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={styles.welcomecontainer}>
            <Starfield />  {/* Add the Starfield component here */}
            <div className={`${styles.content} ${beamingUp ? styles.beamingUp : ''}`}>
                <p className={`${styles.terminalText} ${displayedText2 ? styles.noCursor : ''}`}>{displayedText1}</p>
                <p className={`${styles.terminalText} ${displayedText3 ? styles.noCursor : ''}`}>{displayedText2}</p>
                <p className={styles.terminalText}>{displayedText3}</p>
                <button
                    className={`${styles.enterButton} ${buttonVisible ? styles.visible : styles.hidden}`}
                    onClick={handleButtonClick}
                >
                    Enter the Mothership
                </button>
            </div>
            {beamingUp && <div className={styles.beamEffect}></div>}
        </div>
    );
}

export default Welcome;