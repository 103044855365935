import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import './GovNav.css';

const GovNav: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>    
      <nav className="gov-nav">
        <Link to="/">Home</Link>
        <Link to="/governance">Governance</Link>
        <Link to="/submit">Submit a Proposal</Link>
        <Link to="/live">View Live Proposals</Link>
        <Link to="/finalized">Finalized Proposals</Link>
        <WalletMultiButton />
      </nav>
      <div className="gov-nav2">
        <WalletMultiButton />
        <div className={`navbar2-links2 ${menuOpen ? 'open' : ''}`}>
          <ul>
            <h1 className="linkstitle">NAVIGATION</h1>
            <h2><Link to="/">Home</Link></h2>
            <h2><Link to="/governance">Governance Home</Link></h2>
            <h2><Link to="/submit">Submit Proposal</Link></h2>
            <li><Link to="/live">Live Proposals</Link></li>
            <h2><Link to="/finalized">Finalized Proposals</Link></h2>
          </ul>
        </div>
        <div className="menu-toggle2" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar2"></span>
          <span className="bar3"></span>
          <span className="bar4"></span>
        </div>
      </div>
    </>
  );
};

export default GovNav;