// components/FilterMapA.tsx
import React from 'react';

const FilterMapA = () => {
  return (
    <svg viewBox="0 0 1920 1080" preserveAspectRatio="none" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 10 }}>
      <image href="/Buttons.png" width="1920" height="1080" />
      <a href="https://discord.com/invite/mbJKQyCCkQ" target="_blank">
        <circle cx="1784" cy="772" r="79" fill="transparent" />
      </a>
      <a href="/mothership">
        <circle cx="148" cy="306" r="77" fill="transparent" />
      </a>
      <a href="/alerts">
        <circle cx="142" cy="771" r="78" fill="transparent" />
      </a>
      <a href="https://twitter.com/GalacticHeist">
        <circle cx="1778" cy="303" r="78" fill="transparent" />
      </a>
    </svg>
  );
};

export default FilterMapA;
