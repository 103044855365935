import React, { useEffect } from 'react';
import styles from '../styles/Starfield2.module.css';

const Starfield2 = () => {
    useEffect(() => {
        const starfield = document.querySelector(`.${styles.starfield}`);
        const starCount = 100;
        const glowingStarCount = 50;
        const cometCount = 4;

        if (starfield) {
            const colors = ['#ffffff', '#ff9999', '#ffcc99', '#ffff99', '#99ccff'];
            for (let i = 0; i < starCount; i++) {
                const star = document.createElement('div');
                star.classList.add(styles.star);
                star.style.left = `${Math.random() * 100}vw`;
                star.style.top = `${Math.random() * 100}vh`;
                star.style.animationDelay = `${Math.random() * 10}s`;
                star.style.background = colors[Math.floor(Math.random() * colors.length)];
                starfield.appendChild(star);
            }

            for (let i = 0; i < glowingStarCount; i++) {
                const glowingStar = document.createElement('div');
                glowingStar.classList.add(styles.glowingStar);
                glowingStar.style.left = `${Math.random() * 100}vw`;
                glowingStar.style.top = `${Math.random() * 100}vh`;
                glowingStar.style.animationDelay = `${Math.random() * 10}s`;
                
                const glow = document.createElement('div');
                glow.classList.add(styles.glow);
                glowingStar.appendChild(glow);
                
                starfield.appendChild(glowingStar);
            }

            for (let i = 0; i < cometCount; i++) {
                const comet = document.createElement('div');
                comet.classList.add(styles.comet);
                comet.style.left = `${Math.random() * 100}vw`;
                comet.style.top = `${Math.random() * 100}vh`;
                comet.style.animationDelay = `${Math.random() * 30}s`;
                starfield.appendChild(comet);
            }
        }
    }, []);

    return (
        <>
            <div className={styles.nebula}></div>
            <div className={styles.starfield}></div>
        </>
    );
};

export default Starfield2;