import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GovNav from './GovNav';
import './FinalizedProposals.css';

const FinalizedProposals: React.FC = () => {
  const [proposals, setProposals] = useState<any[]>([]);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/proposals`);
        setProposals(response.data.filter((proposal: any) => Date.now() >= proposal.endTime));
      } catch (error) {
        console.error('Error fetching proposals:', error);
      }
    };

    fetchProposals();
  }, []);

  return (
    <div>
      <GovNav />
      <div className="finalwrapper">
      <div className="finalized-proposals">
        <h2>Finalized Proposals</h2>
        <ul>
          {proposals.map((proposal) => (
            <li key={proposal.id}>
              <h3>{proposal.title}</h3>
              <p><strong>Description:</strong> {proposal.description}</p>
              <p><strong>Submitted by:</strong> {proposal.walletAddress}</p>
              <p><strong>Ended on:</strong> {new Date(proposal.endTime).toLocaleString()} EST</p>
              <p><strong>Votes:</strong> (Yes: {proposal.votes.yes}) | (No: {proposal.votes.no})</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </div>
  );
};

export default FinalizedProposals;