import React, { useEffect, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import axios from 'axios';
import '../pages/portfolio.css';

const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';
const TOKEN_MINT_ADDRESS = 'ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj';
const DEX_SCREENER_API_URL = 'https://api.dexscreener.com/latest/dex/tokens/';

const Port: React.FC = () => {
    const wallet = useWallet();
    const [balance, setBalance] = useState<number | null>(null);
    const [tokenBalance, setTokenBalance] = useState<number | null>(null);
    const [circulatingSupply, setCirculatingSupply] = useState<number | null>(null);
    const [tokenPrice, setTokenPrice] = useState<number | null>(null);

    useEffect(() => {
        const connection = new Connection(SOLANA_RPC_ENDPOINT);

        const fetchBalance = async () => {
            if (wallet.publicKey) {
                try {
                    const balance = await connection.getBalance(wallet.publicKey);
                    console.log('Fetched SOL balance:', balance);
                    setBalance(balance / 1e9);
                } catch (error) {
                    console.error('Failed to get balance:', error);
                }
            }
        };

        const fetchTokenData = async () => {
            if (wallet.publicKey) {
                try {
                    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {
                        mint: new PublicKey(TOKEN_MINT_ADDRESS)
                    });

                    if (tokenAccounts.value.length > 0) {
                        const tokenAmount = tokenAccounts.value[0].account.data.parsed.info.tokenAmount.uiAmount;
                        console.log('Fetched token balance:', tokenAmount);
                        setTokenBalance(tokenAmount);
                    } else {
                        console.log('No token accounts found for this wallet');
                        setTokenBalance(0);
                    }

                    const response = await axios.get(`${DEX_SCREENER_API_URL}${TOKEN_MINT_ADDRESS}`);
                    const price = response.data.pairs[0].priceUsd;
                    console.log('Fetched token price:', price);
                    setTokenPrice(price);
                } catch (error) {
                    console.error('Failed to get token data:', error);
                    setTokenBalance(0);
                }
            }
        };

        const fetchCirculatingSupply = async () => {
            try {
                const supplyResponse = await connection.getTokenSupply(new PublicKey(TOKEN_MINT_ADDRESS));
                setCirculatingSupply(supplyResponse.value.uiAmount); // Adjust according to the actual decimals of $MORPH
            } catch (error) {
                console.error('Error fetching circulating supply:', error);
                setCirculatingSupply(null);
            }
        };

        fetchBalance();
        fetchTokenData();
        fetchCirculatingSupply();
    }, [wallet.publicKey]);

    const percentageOfSupply = tokenBalance && circulatingSupply ? (tokenBalance / circulatingSupply) * 100 : 0;
    const tokenValueInUSD = tokenBalance && tokenPrice ? tokenBalance * tokenPrice : 0;

    return (
        <>
        <div className="portfolio-page">
            <h1>$MORPH-FOLIO!</h1>
            <img className="morphtoken" src="/images/morphtoken.png" alt="morphtoken" />
            <h3>CONNECT TO VIEW YOUR $MORPH STATS!</h3>
            <WalletMultiButton />
            {wallet.connected && (
                <div className="portfolio-details">
                    <p><strong>SOL Balance:&nbsp;</strong>{' '}{balance !== null ? balance.toFixed(4) : 'Loading...'}{' '}SOL</p>
                    <p><strong>Token Balance:&nbsp;</strong>{' '}{tokenBalance !== null ? (tokenBalance > 0 ? tokenBalance.toFixed(2) : '0') : 'Loading...'}{' '}$MORPH</p>
                    <p><strong>Percentage of Supply:&nbsp;</strong>{' '}{tokenBalance ? percentageOfSupply.toFixed(4) : 0}%</p>
                    <p><strong>Current Estimated USD Value:&nbsp;</strong>{' '}${tokenBalance ? tokenValueInUSD.toFixed(2) : 0} USD</p>
                </div>
            )}
        </div>
        </>
    );
};

export default Port;