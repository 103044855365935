
import React from 'react';
import HoomanMap from '../components/HoomanMap';
import styles from '../styles/Hybrids.module.css'
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const Hoomans: React.FC = () => {
  return (
    <>
    <div className={styles.aliencontainer}>
      <HoomanMap />
    </div>

    <div className={styles.mobilehomecontainer}>
      <AlienMap2/>
      <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
      <div className={styles.container2}>
          <p className={styles.alientitle}>HOOMANS!</p>
          <p className={styles.subtitle}>Utilities</p>
          <ul className={styles.subtexts}>
          <li >-CCO IP USAGE</li>
          <li >-CAN STAKE FOR $MORPH</li>
          <li >-GALACTIC GOVERNANCE VOTING AND REWARDS</li>
          <li >-TRAIT UPGRADES THROUGH TRAIT STORE AND REWARDS</li>
          <li >-HOLDER RAFFLES FOR $MORPH, 1/1 ALIEN INSCRIPTIONS, OTHER NFTS, COINS, AND MORE!</li>
          <li >-ACCESS TO THE HYBRIDS MINT</li>
          </ul>
          <img className={styles.doc}src="/hooman8.png" alt="alien" />
          <div className={styles.linkgrid}>
          <Link className={styles.magiceden1} to="https://magiceden.io/marketplace/hoomans_?status=%22buy_now%22">Magic Eden</Link>
          <Link className={styles.magiceden1} to="https://anybodies.com/c/sagaaliens">Staking</Link>
          <Link className={styles.magiceden1} to="/hooman">Filter</Link>
          </div>
          
      </div>
    </div>
  </>
  );
}

export default Hoomans;
