import React, { useState } from 'react';
import { chatService } from '../services/api';
import Starfield from './Starfield';
import Avatar from './Avatar';
import ChartTool from './ChartTool';
import JupiterTerminal from './Jupiter';
import emailjs from 'emailjs-com';
import './Tester.css';

interface InputAreaProps {
    setIsLoading: (isLoading: boolean) => void;
    sendMessage: (message: string, imageDataUrl?: string, isRequestForInput?: boolean) => void;
    showMenu: boolean; // New prop to check if the menu is shown
    toggleMenu: () => void;  // New prop to toggle menu visibility
}

let currentAudio: HTMLAudioElement | null = null;

const stopSpeaking = () => {
    if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        currentAudio = null;
        window.dispatchEvent(new Event('stopSpeaking'));
    }
};

const InputTest: React.FC<InputAreaProps> = ({ setIsLoading, sendMessage, showMenu, toggleMenu }) => {
    const [userInput, setUserInput] = useState('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [showHelpPopup, setShowHelpPopup] = useState(false);
    const [showBugReportPopup, setShowBugReportPopup] = useState(false);

    const handleSend = () => {
        if (!userInput.trim() && !selectedImage) return;
        setIsLoading(true);
        if (selectedImage) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target?.result as string;
                sendMessage(userInput, imageDataUrl);
            };
            reader.readAsDataURL(selectedImage);
        } else {
            sendMessage(userInput);
        }
        setUserInput('');
        setSelectedImage(null);
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
        }
    };



    const toggleHelpPopup = () => {
        setShowHelpPopup(!showHelpPopup);
    };

    const toggleBugReportPopup = () => {
        setShowBugReportPopup(!showBugReportPopup);
    };

    const handleBugReportSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const bugDetails = formData.get('bugDetails') as string;

        const emailParams = {
            message: bugDetails,
        };

        emailjs.send('service_99dfxgd', 'template_qjyvw9w', emailParams, 'QLfO2VmIK7U2VVdIw')
            .then((result) => {
                console.log('Bug report sent:', result.text);
                alert('Bug report sent! We will address it as soon as possible.');
            }, (error) => {
                console.error('Bug report send error:', error.text);
                alert('Failed to send bug report. Please try again later.');
            });

        setShowBugReportPopup(false);
    };

    return (
        <div className="input-area" style={{ width: showMenu ? '85dvw' : '100dvw' }}> {/* Adjust width based on menu visibility */}
            <div className="input-row">
                <input
                    type="text"
                    className="user-input"
                    placeholder="Type your message..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                />
                <button className="send-btn glow-effect" onClick={handleSend}>Send</button>
                {!showMenu && (  // Show button only when menu is hidden
                    <button className="menu-btn glow-effect" onClick={toggleMenu}>Menu</button>
                )}
            </div>
            
        </div>
    );
};

export default InputTest;