import { Helmet } from 'react-helmet';
import styles from '../styles/Utilities.module.css';
import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // If using react-icons for the arrows

const utilitiesData = [
  { id: 1, title: 'Tax', content: '1% Tax on all transfers, buys, and sells. Tax income will be utilized in a variety of ways, which are listed below.' },
  { id: 2, title: 'Burns', content: 'Portions of the tax received will be burnt to create a deflationary supply.' },
  { id: 3, title: 'Free Holder Raffles', content: 'Portions of the tax will be used to purchase a variety of items to be raffled between SAGA ALIENS holders, $MORPH holders, or to both at the same time. This can include NFTs, physical goods, services, and more. We will ensure both holder groups receive raffles that are similar in value. Holders from both communities will be able to vote on specific items to be raffled.' },
  { id: 4, title: 'Holding Amount Based Raffles', content: 'Use $MORPH to gain entry into raffles, requiring holders to have a specific amount during snapshots.' },
  { id: 5, title: 'Charity', content: 'Portions of the tax received will be sold and used to donate to charities chosen by holders votes.' },
  { id: 6, title: '$MORPH Claims', content: '$MORPH will be able to be used to purchase direct listings, auctions, and more within our ecosystem. This can include ecosystem offerings that are created by other artists.' },
  { id: 7, title: 'Coin Rewards', content: 'Portions of the tax will be used to purchase other projects coins. Coins purchased will be raffled to both holder communities.' },
  { id: 8, title: 'LP Bolstering', content: 'We will periodically use portions of the SAGA ALIENS royalty income to further strengthen the $MORPH LP.' },
  { id: 9, title: 'Partner Programs', content: 'Partnerships with other projects to increase the awareness of $MORPH, including small amounts of $MORPH to raffle to their holders.' },
  { id: 10, title: 'Partner Programs #2', content: 'The team will utilize $MORPH to bring on artists to create 1/1s and open editions. These NFT sales/raffles/auctions will occur in $MORPH and the majority of the proceeds will be burnt.' },
  { id: 11, title: 'Partner Programs #3', content: 'We will partner with other projects and service providers to have $MORPH accepted as access and/or provide discounts to $MORPH/SAGA ALIENS holders.' },
  { id: 12, title: 'Staking', content: 'SAGA ALIENS holders will be able to stake their aliens to earn $MORPH. 10% of the total supply will be allocated to this program and it will remain open until all tokens have been claimed. The staking program will be produce rewards for holders at the same rates per NFT and not trait based.' },
  { id: 13, title: 'Staking #2', content: 'We will be incorporating the SAGA ALIENS 1/1s into the staking program as well. These will earn $MORPH at a slightly higher rate than SAGA ALIENS.' },
  { id: 14, title: 'Staking #3', content: 'We would like for the staking program to continue after the 10% of the supply allocated to it is claimed. To accomplish this, we may fund new staking rounds with $MORPH, as well as additional tokens from other projects. New tokens and additional $MORPH will be acquired through the $MORPH tax income, as well as SAGA ALIENS royalties.' },
  { id: 15, title: 'Cross Chain Promotions', content: 'Portions of the tax will be used to acquire NFTs from other popular chains. These NFTs will then be raffled to holders from both of our communities.' },
  { id: 16, title: 'Trait Store', content: 'Use $MORPH to upgrade the traits of your SAGA ALIENS! This will roll out over the next month and will be a manual process at first.' },
  { id: 17, title: 'Abductions', content: 'From time to time, we will purchase NFTs from other collections, preferably rarer ones. These will be "ransomed" off in $MORPH and returned to that projects team if the $MORPH payment is met. If not, then the NFT will be burnt. A new "abduction wallet" will be created for each instance of an "abduction" occurring. This will contain the NFT and be the wallet to send $MORPH to in order to release the NFT.' },
  { id: 18, title: 'Abductions #2', content: 'Holders of both communities will be able to vote on the projects to target, as well as the specific NFT in that project to acquire' },
  { id: 19, title: 'Abductions #3', content: 'We would like to create a way for our holders to participate in this program as well. Essentially, holders themselves could "abduct" NFTs and then send them to a wallet we create. The holder would then get a portion of the $MORPH "ransom" received.' },
  { id: 20, title: 'Services', content: 'Any services we create, such as helping people with NFT art generation, the minting process, and more can be obtained by using $MORPH as the access token. Portions of $MORPH received will be burnt.' },
  { id: 21, title: 'Competitions', content: 'Any services we create, such as helping people with NFT art generation, the minting process, and more can be obtained by using $MORPH as the access token. Portions of $MORPH received will be burnt.' },
  { id: 22, title: 'DAO-Lite', content: 'Portions of the tax will be placed into a community wallet. Holders will be able to submit proposals on what to do with those proceeds. To keep things legally compliant, this can not include direct payments back to holders.' },
  { id: 23, title: 'Future Mints', content: '$MORPH will be utilized in our upcoming mint codenamed "Hybrids". SAGA ALIENS holders will receive "Hoomans" NFTs that they can then "probe" with $MORPH. This will result in a new NFT.' },
  { id: 24, title: 'CEX Listings', content: 'We will work to secure CEX listings over time if the community votes to do so.' },
];

const Utilities = () => {
  const [openUtilities, setOpenUtilities] = useState<number[]>([]);
  const [isToggleAll, setIsToggleAll] = useState<boolean>(false);

  const toggleUtility = (id: number) => {
      setOpenUtilities(openUtilities.includes(id) 
      ? openUtilities.filter(utilityId => utilityId !== id) 
      : [...openUtilities, id]);
  };

  const toggleAllUtilities = () => {
      if (isToggleAll) {
          setOpenUtilities([]);
      } else {
          setOpenUtilities(utilitiesData.map(utility => utility.id));
      }
      setIsToggleAll(!isToggleAll);
  };

  return (
      <>
          <Helmet>
              <title>$MORPH Utilities</title>
              <meta name="description" content="Discover all the utilities $MORPH and SAGA ALIENS." />
          </Helmet>
          <div className={styles.container}>
              <section className={styles.utilitiesSection}>
                  <h1 className={styles.title}>SAGA ALIENS & $MORPH UTILITIES</h1>
                  <p className={styles.description}>
                      Additional items will be added to the list below over time as we create new concepts and utilize new technologies that come into the space.
                  </p>
                  <button onClick={toggleAllUtilities} className={styles.toggleAllButton}>
                      {isToggleAll ? 'Collapse All' : 'Expand All'}
                  </button>
                  <ul className={styles.utilitiesList}>
                      {utilitiesData.map((utility) => (
                          <li key={utility.id} className={styles.utilityItem}>
                              <div onClick={() => toggleUtility(utility.id)} className={styles.utilityHeader}>
                                  <strong>{utility.title}</strong>
                                  {openUtilities.includes(utility.id) || isToggleAll ? <FiChevronUp /> : <FiChevronDown />}
                              </div>
                              <div className={openUtilities.includes(utility.id) || isToggleAll ? styles.utilityContentOpen : styles.utilityContentClosed}>
                                  {utility.content}
                              </div>
                          </li>
                      ))}
                  </ul>
              </section>
          </div>
      </>
  );
};

export default Utilities;
