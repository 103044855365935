import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    LedgerWalletAdapter,
    TorusWalletAdapter,
    BitpieWalletAdapter,
    MathWalletAdapter,
    CloverWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import './App.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import { useState } from 'react';
import Home from './pages/mothership';
import Aliens from './pages/aliens'
import Alien from './pages/alien'
import Hoomans from './pages/hoomans'
import OneOfs from './pages/1o1s';
import Hybrids from './pages/hybrids';
import Tokenomics from './pages/tokenomics';
import TeamInfo from './pages/teaminfo';
import Utilities from './pages/utilities';
import Hooman from './pages/hooman';
import AlienPage from './pages/alienttest';
import IntergalacticHub from './pages/intergalactichub';
import Morph from './pages/morph';
import Governance from './pages/Governance';
import ProposalList from './pages/ProposalList';
import ProposalSubmission from './pages/ProposalSubmission';
import FinalizedProposals from './pages/FinalizedProposals';
import Faq from './pages/faq';
import RotateDevicePrompt from './components/RotateDevicePrompt';
import Alerts from './pages/alerts';
import Team from './pages/team';
import Portfolio from './pages/portfolio';
import Port from './pages/port';
import Data from './pages/data';
import MorphData from './pages/morphdata';
import Welcome from './pages/welcome';
import Mothership from './pages/mothership';
import TraitStore from './pages/TraitStore';
import { NFTProvider } from './components/NFTContext';
import DaveyAi from './components/DaveyAi';
import DaveyTest from './components/DaveyTest';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Check if the current pathname is the root (splash page), adjust condition as needed
  const handleLogin = () => {
    console.log("Admin logged in");
  };
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = 'https://api.mainnet-beta.solana.com';
  const wallets = [
    new PhantomWalletAdapter(),
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
    new BitpieWalletAdapter(),
    new MathWalletAdapter(),
    new CloverWalletAdapter(),
  ];

  return (
    <Router>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
          <NFTProvider>
            <div className="App">
              <div className="main-content">
                <Routes>
                  <Route path="/mothership" element={<Mothership />} />
                  <Route path="/aliens" element={<Aliens />} />
                  <Route path="/alien" element={<Alien />} />
                  <Route path="/alientest" element={<AlienPage />} />
                  <Route path="/hoomans" element={<Hoomans />} />
                  <Route path="/hooman" element={<Hooman />} />
                  <Route path="/1o1s" element={<OneOfs />} />
                  <Route path="/hybrids" element={<Hybrids />} />
                  <Route path="/morph" element={<Morph />} />
                  <Route path="/intergalactichub" element={<IntergalacticHub />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/utilities" element={<Utilities />} />
                  <Route path="/governance" element={<Governance />} />
                  <Route path="/submit" element={<ProposalSubmission />} />
                  <Route path="/live" element={<ProposalList />} />
                  <Route path="/finalized" element={<FinalizedProposals />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/alerts" element={<Alerts />} />
                  <Route path="/tokenomics" element={<Tokenomics />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/morphdata" element={<MorphData />} />
                  <Route path="/" element={<Welcome />} />
                  <Route path="/traitstore" element={<TraitStore />} />
                  <Route path="/daveyai" element={<DaveyAi />} />
                  
                </Routes>
                <div className="wallet-button">
                </div>
              </div>
            </div>
            </NFTProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Router>
  );
};

export default App;
