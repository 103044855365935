import React from 'react';
import HubMap from '../components/HubMap';
import styles from '../styles/Hybrids.module.css';
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const IntergalacticHub: React.FC = () => {
  return (
    <>
    <div className={styles.igcontainer}>
      <div className={styles.hybridcontainer}>
        <HubMap />
        <p className={styles.hubtitle}>Intergalactic Hub</p>
        <div className={styles.hubgrid}>
          <a href="/governance" className={styles.hubitem}>Governance</a>
          <a href="/daveyai" className={styles.hubitem}>Davey Ai</a>
          <a href="https://zealy.io/cw/galacticheistsyndicate/questboard" className={styles.hubitem}>Universal Ambassador Program (UAP)</a>
          <a href="https://zealy.io/cw/galacticheistsyndicate/questboard/fd93d8fb-dd35-41dd-a815-bc5b699c0699/c94a2241-31c3-4bd6-ae0c-d1e1abcc9f27" className={styles.hubitem}>Art Submissions</a>
        </div>
      </div>
    </div>

    <div className={styles.mobilehomecontainer}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <p className={styles.hubtitle}>Intergalactic Hub</p>
        <div className={styles.hubgrid}>
          <a href="/governance" className={styles.hubitem}>Governance</a>
          <a href="https://zealy.io/cw/galacticheistsyndicate/questboard" className={styles.hubitem}>Universal Ambassador Program (UAP)</a>
          <a href="https://zealy.io/cw/galacticheistsyndicate/questboard/fd93d8fb-dd35-41dd-a815-bc5b699c0699/c94a2241-31c3-4bd6-ae0c-d1e1abcc9f27" className={styles.hubitem}>Art Submissions</a>
        </div>

        </div>
      

    </>
  );
}

export default IntergalacticHub;