import React from 'react';
import AlienMap from '../components/AlienMap';
import styles from '../styles/Hybrids.module.css'
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const Aliens: React.FC = () => {
  return (
    <>
      <div className={styles.aliencontainer}>
        <AlienMap />
      </div>

      <div className={styles.mobilehomecontainer}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <div className={styles.container2}>
          <p className={styles.alientitle}>ALIENS!</p>
          <p className={styles.subtitle}>Utilities</p>
          <ul className={styles.subtexts}>
            <li >-CCO IP USAGE</li>
            <li >-CAN STAKE FOR $MORPH</li>
            <li >-GALACTIC GOVERNANCE VOTING AND REWARDS</li>
            <li >-TRAIT UPGRADES THROUGH TRAIT STORE AND REWARDS</li>
            <li >-HOLDER RAFFLES FOR $MORPH, 1/1 ALIEN INSCRIPTIONS, OTHER NFTS, COINS, AND MORE!</li>
            <li >-ACCESS TO HOOMANS AND HYBRIDS MINTS</li>
          </ul>
          <img className={styles.doc} src="/docsalien.png" alt="alien" />
          <div className={styles.linkgrid}>
            <Link className={styles.magiceden1} to="https://magiceden.io/marketplace/saga_aliens?status=%22buy_now%22">Magic Eden</Link>
            <Link className={styles.magiceden1} to="https://anybodies.com/c/sagaaliens">Staking</Link>
            <Link className={styles.magiceden1} to="/1o1s">1 of 1's</Link>
            <Link className={styles.magiceden1} to="/alien">Filter</Link>
          </div>

        </div>
      </div>
    </>

  );
}

export default Aliens;
