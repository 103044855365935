import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import GovNav from './GovNav';
import Modal from './Modal';
import './ProposalList.css';

const ProposalList: React.FC = () => {
  const wallet = useWallet();
  const [proposals, setProposals] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/proposals`);
        setProposals(response.data.filter((proposal: any) => Date.now() < proposal.endTime));
      } catch (error) {
        console.error('Error fetching proposals:', error);
      }
    };

    fetchProposals();
  }, []);

  const handleVote = async (id: number, vote: 'yes' | 'no') => {
    if (!wallet.publicKey) {
      setMessage('Please Connect Your Wallet');
      setShowModal(true);
      return;
    }

    try {
      const walletAddress = wallet.publicKey.toBase58();
      await axios.post(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/vote`, { vote, walletAddress });
      setProposals(proposals.map((proposal) =>
        proposal.id === id ? { ...proposal, votes: { ...proposal.votes, [vote]: proposal.votes[vote] + 1 } } : proposal
      ));
      setMessage('Vote Submitted Successfully!');
    } catch (error: any) {
      setMessage(error.response?.data || 'An error occurred');
    } finally {
      setShowModal(true);
    }
  };

  return (
    <div>
      <GovNav />
      <div className="listwrapper">
      <div className="proposal-list">
        <h2>Live Proposals</h2>
        <p>(Sorted by End Date)</p>
        {showModal && <Modal message={message} onClose={() => setShowModal(false)} />}
        <ul>
          {proposals.map((proposal) => (
            <li key={proposal.id}>
              <h3>{proposal.title}</h3>
              <p><strong>Description:</strong> {proposal.description}</p>
              <p><strong>Submitted by:</strong> {proposal.walletAddress}</p>
              <p><strong>Ends on:</strong> {new Date(proposal.endTime).toLocaleString()} EST</p>
              <p><strong>Votes:</strong> (Yes: {proposal.votes.yes}) | (No: {proposal.votes.no})</p>
              <button onClick={() => handleVote(proposal.id, 'yes')}>Vote Yes</button>
              <button onClick={() => handleVote(proposal.id, 'no')}>Vote No</button>
            </li>
          ))}
        </ul>
      </div>
      </div>
    </div>
  );
};

export default ProposalList;