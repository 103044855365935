import React from 'react';
import MorphMap from '../components/MorphMap';
import Tokenomics from './tokenomics';
import styles from '../styles/Hybrids.module.css';
import AlienMap2 from '../components/AlienMap2';
import TeamInfo from './teaminfo';
import { useEffect } from 'react';

const Team: React.FC = () => {
  return (
    <>
    <div className={styles.container3}>
      <MorphMap />
      <div className={styles.overlay}>
        <TeamInfo />
      </div>
    </div>

    <div className={styles.mobilecontainer}>
        <AlienMap2/>
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />     
        <TeamInfo/>
      </div>

    </>
  );
}

export default Team;