
import React from 'react';
import AlertMap from '../components/AlertMap';
import styles from '../styles/Hybrids.module.css'
import Team from './teaminfo';
import AlienMap2 from '../components/AlienMap2';
import { Link } from 'react-router-dom';

const FAQ: React.FC = () => {
  return (
    <>
      <div className={styles.faqcontainer}>
        <div className={styles.hybridcontainer}>
          <AlertMap />
          <p className={styles.faqtitle}>FAQ!</p>
          <div className={styles.faqbox}>
            <p className={styles.alerttext4}>Click the items below to visit their page!</p>
           <Link to="/team" className={styles.alerttext3}>Team Info</Link>
          </div>
        </div>
      </div>

      <div className={styles.mobilehomecontainer}>
        <AlienMap2 />
        <img className={styles.mapImage} src="/mobilepage.gif" alt="" />
        <p className={styles.faqtitle2}>FAQ!</p>
        <div className={styles.faqbox}>
            <p className={styles.alerttext4}>Click the items below to visit their page!</p>
           <Link to="/team" className={styles.alerttext3}>Team Info</Link>
        </div>
      </div>


    </>
  );
}

export default FAQ;
