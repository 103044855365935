// components/Collapsible.tsx

import React, { useState, ReactNode } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface CollapsibleProps {
    title: string;
    count: number;  // Total count of traits in this category
    children: ReactNode;
  }
  
  const CollapsibleM: React.FC<CollapsibleProps> = ({ title, count, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div>
        <button onClick={() => setIsOpen(!isOpen)} style={{ background: 'none', color: 'lightgreen', border: 'none', width: '100%', textAlign: 'left', display: 'flex', justifyContent: 'space-between', fontSize:'2.5dvw', alignItems: 'center', padding: '0.5rem 0', marginTop: '1.5dvw' }}>
          <span>{title} ({count})</span>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {isOpen && <div>{children}</div>}
      </div>
    );
  };
  
  export default CollapsibleM;
