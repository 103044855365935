import React, { useState } from 'react';
import './MemeChartTool.css'

interface MemeChartToolProps {
  onSubmit: (contractAddress: string, chain: string) => void;
}

const MemeChartTool: React.FC<MemeChartToolProps> = ({ onSubmit }) => {
  const [contractAddress, setContractAddress] = useState('');
  const [selectedChain, setSelectedChain] = useState('solana');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(contractAddress, selectedChain);
  };

  return (
    <div className="meme-chart-tool-debug">
      <h3>Meme ChartTool</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="debug-contract-address">Contract Address:</label>
          <input
            id="debug-contract-address"
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
            placeholder="Enter contract address"
          />
        </div>
        <div>
          <label htmlFor="debug-chain-select">Chain:</label>
          <select
            id="debug-chain-select"
            value={selectedChain}
            onChange={(e) => setSelectedChain(e.target.value)}
          >
            <option value="solana">SOL</option>
            <option value="ethereum">ETH</option>
            <option value="base">BASE</option>
            <option value="bsc">BSC</option>
            <option value="arbitrum">ARB</option>
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default MemeChartTool;