import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : 'https://api.ghsyn.xyz';

console.log('API_URL:', API_URL);

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add this logging interceptor
api.interceptors.request.use(function (config) {
    console.log(`Making request to: ${config.baseURL}${config.url}`);
    return config;
}, function (error) {
    return Promise.reject(error);
});


export const chatService = {
    checkAccess: async (walletAddress: string) => {
        try {
            const response = await api.post('/api/check-access', { walletAddress });
            return response.data.hasAccess;
        } catch (error) {
            console.error('Error checking access:', error);
            return false;
        }
    },

    sendMessage: async (message: any, conversationId: string, walletAddress: string) => {
        try {
            const response = await api.post('/api/chat', {
                message,
                conversation_id: conversationId,
                wallet_address: walletAddress
            });
            return response.data;
        } catch (error) {
            console.error('Error in sendMessage:', error);
            throw error;
        }
    },

    getConversations: async (walletAddress: string) => {
        try {
            console.log('Fetching conversations for wallet:', walletAddress);
            const response = await api.get('/api/conversations', {
                params: { wallet_address: walletAddress }
            });
            console.log('API Response:', response.data);
            return response.data; // This should be an array of conversation objects
        } catch (error) {
            console.error('Error fetching conversations:', error);
            throw error;
        }
    },

    getConversation: async (conversationId: string, walletAddress: string) => {
        try {
            const response = await api.get(`/api/conversation/${conversationId}`, {
                params: { wallet_address: walletAddress }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching conversation:', error);
            throw error;
        }
    },

    fetchTokenData: async (contractAddress: string) => {
        const response = await api.post('/api/fetch-token-data', { contractAddress });
        return response.data;
    },

    generateImage: async (prompt: string) => {
        const response = await api.post('/api/generate-image', { prompt });
        return response.data;
    },

    generateSummary: async (conversation: string) => {
        const response = await api.post('/api/summary', { conversation });
        return response.data;
    },

    textToSpeech: async (text: string) => {
        const response = await api.post('/api/text-to-speech', { text }, { responseType: 'blob' });
        return response.data;
    },

    // Rename function in your service/api.ts
    deleteConversation: async (id: string, walletAddress: string) => {
        try {
            const response = await api.delete(`/api/delete-conversation/${id}`, {
                params: { wallet_address: walletAddress }
            });
            if (response.data.success) {
                return true;
            } else {
                throw new Error(response.data.error || 'Failed to delete conversation');
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
            throw error;
        }
    },

    renameConversation: async (id: string, name: string, walletAddress: string) => {
        try {
          const response = await api.post('/api/rename-conversation', { id, name, wallet_address: walletAddress });
          return response.data;
        } catch (error) {
          console.error('Error renaming conversation:', error);
          throw error;
        }
      },

    


};

export default api;