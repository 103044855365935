// components/ResponsiveImageMap.tsx
import React from 'react';
import styles from '../styles/Hybrids.module.css';

const AlienMap2 = () => {
  return (
    <svg className={styles.overlayMap} viewBox="0 0 1920 1080" preserveAspectRatio="none">
      <a href="/alerts">
      <rect x="280" y="35" width="350" height="100" fill="transparent" opacity=".5" />
      </a>
      <a href="/mothership">
      <rect x="1250" y="35" width="350" height="100" fill="transparent" opacity=".5" />
      </a>
      <a href="https://discord.com/invite/mbJKQyCCkQ">
      <rect x="280" y="950" width="350" height="100" fill="transparent" opacity=".5" />
      </a>
      <a href="https://x.com/GalacticHeist">
      <rect x="1275" y="950" width="350" height="100" fill="transparent" opacity=".5" />
      </a>
    </svg>
  );
};

export default AlienMap2;